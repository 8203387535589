import axios from "axios";
import { Buffer } from "buffer";
import * as Sentry from "@sentry/browser";

const DEV_JWT = process.env.REACT_APP_DEV_JWT;
const API_URL = process.env.REACT_APP_API_URL;

/**
 * Returns whether the user has a valid token or not
 * @returns
 */
export function isLoggedIn() {
  let user = getUser();

  if (user) {
    let expiryDate = new Date(user.exp * 1000);
    if (expiryDate <= new Date()) return false;

    return true;
  } else {
    return false;
  }
}

/**
 * Returns the data stored in the token.
 * @returns
 */
export function getUser() {
  let token = getToken();

  if (token !== "") {
    let tokenSplit = token.split(".");
    if (tokenSplit.length !== 3) return false;

    let jsonString = Buffer.from(tokenSplit[1], "base64").toString();
    let json = JSON.parse(jsonString);

    if (json) Sentry.setContext("UserData", json);

    return json ? json : false;
  } else {
    return false;
  }
}

/**
 * Find the token
 * @returns
 */
export function getToken() {
  // If we're in development mode, use the token from the .env.development file
  if (typeof DEV_JWT !== "undefined" && DEV_JWT !== "") {
    return DEV_JWT;
  }

  if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
    return localStorage.getItem("token");
  } else if (getCookieValue("tc_token") !== "") {
    return getCookieValue("tc_token");
  } else {
    return "";
  }
}

export function logOut() {
  localStorage.removeItem("token");
  window.location.href =
    API_URL + "/v1/user/logout?returnUrl=" + process.env.REACT_APP_URL;
}

export function refreshToken() {
  let user = getUser();

  if (isLoggedIn() && user) {
    let expiryDate = new Date(user.exp * 1000);
    expiryDate.setDate(expiryDate.getDate() - 8);

    // If the token will be expiring within 8 days, renew it.
    if (expiryDate <= new Date()) {
      axios
        .get(API_URL + "/v1/user/refresh", {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
        .then(
          (response) => {
            // Save the new token to the local storage
            if (response.data.token) {
              localStorage.setItem("token", response.data.token);
            }
          },
          (error) => {
            console.log(error);
            logOut();
          }
        );
    }
  }
}

let getCookieValue = (a) => {
  let b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
};
