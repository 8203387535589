let upgrade = (state) => {
  console.log("Checking for upgrade...");

  let newState = state;

  switch (state.spellbookData.version) {
    case undefined:
      newState = version0(newState);

      break;
    case 1:
      newState = version1(newState);

      break;

    default:
      newState = state;
  }

  if (newState !== state) {
    newState = upgrade(newState);
    console.log("Upgraded", newState);
  }

  return newState;
};

/**
 * For upgrading from v0 to v1
 * @param {*} state
 * @returns
 */
function version0(state) {
  let newCustomSpells = {};
  if (typeof state.interfaceData.spellListCategories.Custom !== "undefined") {
    for (const [level, list] of Object.entries(
      state.interfaceData.spellListCategories.Custom
    )) {
      newCustomSpells[level] = [];
      for (let i = 0; i < list.length; i++) {
        newCustomSpells[level].push({
          spellId: list[i],
          isPrepared: false,
        });
      }
    }
  }

  let newProfiles = {};
  for (const [key, profile] of Object.entries(state.spellbookData.profiles)) {
    let newFavorites = {
      ...profile.favoriteSpells,
      Custom: {
        "lvl-0": [],
        "lvl-1": [],
        "lvl-2": [],
        "lvl-3": [],
        "lvl-4": [],
        "lvl-5": [],
        "lvl-6": [],
        "lvl-7": [],
        "lvl-8": [],
        "lvl-9": [],
      },
    };

    for (const [level, customList] of Object.entries(
      profile.favoriteSpells.Custom
    )) {
      for (let spell of customList) {
        newFavorites.Custom[level].push({ isPrepared: false, spellId: spell });
      }
    }

    let newLearnedSpells = {
      cantrip: [],
      first: [],
      second: [],
      third: [],
      fourth: [],
      fifth: [],
      sixth: [],
      seventh: [],
      eighth: [],
      ninth: [],
    };

    for (const [level, spellList] of Object.entries(profile.learnedSpells)) {
      newLearnedSpells[level] = spellList.map((element) => {
        return {
          ...element,
          isPrepared: false,
        };
      });
    }

    newProfiles[key] = {
      ...profile,
      customSpells: newCustomSpells,
      favoriteSpells: newFavorites,
      learnedSpells: newLearnedSpells,
    };
  }

  let newState = {
    ...state,
    spellbookData: {
      ...state.spellbookData,
      profiles: newProfiles,
      version: 1,
    },
    interfaceData: {
      ...state.interfaceData,
      spellListCategories: {
        ...state.interfaceData.spellListCategories,
        Custom: newCustomSpells,
      },
    },
  };

  return newState;
}

/**
 * For upgrading from v1 to v2
 * @param {*} state
 * @returns
 */
function version1(state) {
  // Update the profiles to include a type tag for cloud sync
  let newProfiles = {};
  for (const [key, profile] of Object.entries(state.spellbookData.profiles)) {
    newProfiles[key] = {
      profileVersion: 2,
      type: "local",
      version: 0,
      current: {
        ...profile,
      },
      old: {},
    };
  }

  let newState = {
    ...state,
    spellbookData: {
      ...state.spellbookData,
      profiles: newProfiles,
      version: 2,
    },
    interfaceData: {
      ...state.interfaceData,
      cloudSyncCountdown: 0,
      lastCloudSync: new Date().getTime(),
      isCloudSyncing: false,
    },
  };

  return newState;
}

export { upgrade };
