import { ThemeOptions } from "@material-ui/core";

let theme: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#833471",
      contrastText: "#fff",
    },
    secondary: {
      main: "#48dbfb",
    },
    background: {
      default: "#222f3e",
      paper: "#FFF",
    },
    /*disabled: {
      main: "#FFF",
    },*/
    text: {
      primary: "#fff",
    },
  },
  typography: {},
};

export { theme };
