import { SpellbookData, spellbookData } from "./spellbookData";
import { InterfaceData, interfaceData } from "./interfaceData";
import { Reducer } from "redux";

const reducer: Reducer<State, any> = (
  state: State | undefined,
  action: any
): State => {
  let spellbookRed = spellbookData(
    typeof state == "undefined" ? undefined : state.spellbookData!,
    action
  );
  let interfaceRed = interfaceData(
    typeof state == "undefined" ? undefined : state.interfaceData!,
    action,
    spellbookRed
  );

  return {
    spellbookData: spellbookRed,
    interfaceData: interfaceRed,
  };
};

export default reducer;

export interface State {
  spellbookData: SpellbookData;
  interfaceData: InterfaceData;
}
