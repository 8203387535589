import React, { FunctionComponent, useState } from "react";
import {
  CustomSpell,
  Components,
  UserSpell,
  SpellLevels,
} from "./CustomSpellInterface";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { compose } from "redux";
import { updateUserSpell } from "../../actions/spellbookActions";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import SpellComponentSelector from "../../components/SpellComponentSelector";
import EditableDisplayField from "../../components/EditableDisplayField";
import { Autocomplete } from "@material-ui/lab";
import { ClassNameMap } from "@material-ui/styles";
import SpellLevelSelector from "../../components/SpellLevelSelector";

let styles = (theme: Theme) => ({
  textField: {
    width: "100%",
  },
  saveButton: {
    width: "100%",
    height: 48,
  },
  errorLinkText: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
});

const RANGE_OPTIONS = [
  "personal",
  "touch",
  "close (25 ft. + 5 ft./level)",
  "medium (100 ft. + 10 ft./level)",
  "long (400 ft. + 40 ft./level)",
];

const DURATION_OPTIONS = [
  "instantaneous",
  "1 round",
  "1 round/level",
  "1 minute",
  "1 minute/level",
  "10 minutes",
  "10 minutes/level",
  "1 hour",
  "1 hour/level",
  "1 day",
  "1 day/level",
  "permanent",
];

const SAVING_THROW_OPTIONS = [
  "none",
  "Fortitude partial",
  "Fortitude negates",
  "Reflex half",
  "Reflex negates",
  "Will half",
  "Will negates",
  "See text",
];

const CASTING_TIME_OPTIONS = [
  "free action",
  "1 swift action",
  "1 standard action",
  "1 round",
  "1 minute",
  "10 minutes",
  "1 hour",
  "10 hours",
  "see text",
];

const SPELL_RESISTANCE_OPTIONS = [
  "no",
  "no (harmless)",
  "yes",
  "yes (harmless)",
  "see text",
];

const SCHOOL_OPTIONS = [
  "Conjuration",
  "Enchantment",
  "Transmutation",
  "Abjuration",
  "Divination",
  "Necromancy",
  "Universal",
  "Evocation",
  "Illusion",
];

const AREA_OPTIONS = [
  "10-ft cube",
  "10-ft cube/level",
  "20-ft radius burst",
  "20-ft radius burst, centered on you",
  "30-ft line",
  "15-ft cone",
  "cilinder (10-ft radius, 30-ft high)",
  "2-mile-radius circle, centered on you",
  "see text",
];

const TARGETS_OPTIONS = [
  "you",
  "one willing creature",
  "one willing creature/level",
  "one living creature",
  "one living creature/level",
  "one creature",
  "one creature/level",
  "one weapon",
  "object touched",
  "you and one willing creature",
  "you and one willing creature/2 levels",
];

interface EditCustomSpellProps {
  userSpellId: string;
  userSpells: { [userSpellId: string]: UserSpell };
  closeView: () => void;
  updateUserSpell: (userSpellId: string, spell: UserSpell) => void;
  classes: ClassNameMap;
}

enum EditableFields {
  name = "name",
  school = "school",
  levels = "levels",
  castingTime = "castingTime",
  components = "components",
  range = "range",
  targets = "targets",
  area = "area",
  duration = "duration",
  savingThrow = "savingThrow",
  spellResistance = "spellResistance",
  mindAffecting = "mindAffecting",
  description = "description",
}

function EditCustomSpell({
  userSpellId,
  updateUserSpell,
  closeView,
  userSpells,
  classes,
}: EditCustomSpellProps) {
  const [showEditable, setshowEditable] = useState<{
    [key in EditableFields]: boolean;
  }>({
    name: false,
    school: false,
    levels: false,
    castingTime: false,
    components: false,
    range: false,
    targets: false,
    area: false,
    duration: false,
    savingThrow: false,
    spellResistance: false,
    mindAffecting: false,
    description: false,
  });

  if (!userSpells[userSpellId].data && !userSpells[userSpellId].draftData) {
    return (
      <div>
        Spell not found.{" "}
        <span className={classes.errorLinkText} onClick={() => closeView()}>
          Click here to go back.
        </span>
      </div>
    );
  }

  let currentSpell: CustomSpell;
  if (!userSpells[userSpellId].draftData) {
    currentSpell = userSpells[userSpellId].data!;
  } else {
    currentSpell = userSpells[userSpellId].draftData!;
  }

  let isAreaSpell = userSpells[userSpellId].draftAreaSpell ? true : false;

  /**
   * Changes the state of showEditable to show the specified field as editable.
   * If no field is specified, all fields are set to not editable.
   * @param field - The field to set as editable. If undefined, all fields are set to not editable.
   */
  let changeEditable = (field: EditableFields | undefined) => {
    if (field === undefined) {
      let newState = { ...showEditable };
      for (let key in newState) {
        newState[key as EditableFields] = false;
      }
      setshowEditable(newState);
      return;
    }
    let newState = { ...showEditable };
    for (let key in newState) {
      if (key === field) {
        newState[key] = true;
      } else {
        newState[key as EditableFields] = false;
      }
    }
    setshowEditable(newState);
  };

  /**
   * Updates the name of the current spell draft and saves it to the state.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  let onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateUserSpell(userSpellId, {
      ...userSpells[userSpellId],
      draftData: { ...currentSpell, name: event.target.value },
    });
  };

  /**
   * Updates the description of the current spell draft and saves it to the state.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  let onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateUserSpell(userSpellId, {
      ...userSpells[userSpellId],
      draftData: { ...currentSpell, description: event.target.value },
    });
  };

  /**
   * Callback function for handling changes to an autocomplete field.
   * @param fieldName - The name of the field being updated.
   * @returns A function that takes an event and a value and updates the corresponding field in the spell draft.
   */
  let onAutoCompleteChange =
    <T extends keyof CustomSpell>(fieldName: T) =>
    (event: React.ChangeEvent<{}>, value: string | null) => {
      if (value === null) value = "";

      let newData = { ...currentSpell };
      newData[fieldName] = value as CustomSpell[T];

      updateUserSpell(userSpellId, {
        ...userSpells[userSpellId],
        draftData: newData,
      });
    };

  let onAutoCompleteTextboxChange =
    <T extends keyof CustomSpell>(fieldName: T) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newData = { ...currentSpell };
      newData[fieldName] = event.target.value as CustomSpell[T];

      updateUserSpell(userSpellId, {
        ...userSpells[userSpellId],
        draftData: newData,
      });
    };

  /**
   * Handles the change event of the spell type input element and updates the spell draft accordingly.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   * @returns {void}
   */
  let onAreaSpellChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.value === "area") {
      updateUserSpell(userSpellId, {
        ...userSpells[userSpellId],
        draftAreaSpell: true,
      });
    } else {
      updateUserSpell(userSpellId, {
        ...userSpells[userSpellId],
        draftAreaSpell: false,
      });
    }
  };

  /**
   * Callback function for handling changes to a checkbox field.
   * @param fieldName - The name of the field being updated.
   * @returns A function that takes an event and a value and updates the corresponding field in the spell draft.
   */
  let onCheckboxChange =
    <T extends keyof CustomSpell>(fieldName: T) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let newData = { ...currentSpell };
      newData[fieldName] = checked as CustomSpell[T];

      updateUserSpell(userSpellId, {
        ...userSpells[userSpellId],
        draftData: newData,
      });
    };

  let onSpellLevelChange = (newSpellLevels: SpellLevels) => {
    updateUserSpell(userSpellId, {
      ...userSpells[userSpellId],
      draftData: {
        ...currentSpell,
        spellLevels: newSpellLevels,
      },
    });
  };

  let onSaveClick = () => {
    let savedSpell = {
      ...userSpells[userSpellId],
      data: currentSpell,
      isAreaSpell: userSpells[userSpellId].draftAreaSpell,
    };
    delete savedSpell.draftData;

    updateUserSpell(userSpellId, savedSpell);

    // TODO save in cloud?

    closeView();
  };

  /**
   * The text representation of the components required for the custom spell.
   */
  let componentsText: string = "";
  if (currentSpell.components.verbal) componentsText += "V, ";
  if (currentSpell.components.somatic) componentsText += "S, ";
  if (currentSpell.components.material) componentsText += "M, ";
  if (currentSpell.components.focus) componentsText += "F, ";
  if (currentSpell.components.divineFocus) componentsText += "DF, ";
  componentsText = componentsText.slice(0, -2);

  if (
    currentSpell.components.materialComponents &&
    currentSpell.components.materialComponents !== ""
  ) {
    componentsText += ` (${currentSpell.components.materialComponents})`;
  }

  /**
   * The duration text of the current spell.
   */
  let durationText = currentSpell.duration;
  if (currentSpell.dismissible) durationText += " (D)";

  let areaText = currentSpell.area;
  if (currentSpell.shapeable) areaText += " (S)";

  let levelsText = "";
  for (const [spellClass, spellLevel] of Object.entries(
    currentSpell.spellLevels
  )) {
    levelsText += `${spellClass} ${spellLevel}, `;
  }
  levelsText = levelsText.slice(0, -2); // Remove the last comma and space

  return (
    <div
      onClick={() => {
        changeEditable(undefined);
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <i>
            You can close this screen and come back to it later, your changes
            are saved as a draft. When you've got your spell the way you want
            it, press the publish button at the bottom.
          </i>
        </Grid>
        <Grid item>
          <RadioGroup
            row
            defaultValue="target"
            onChange={onAreaSpellChange}
            value={isAreaSpell ? "area" : "target"}
          >
            <FormControlLabel
              value="target"
              control={<Radio color="primary" />}
              label="Target spell"
            />
            <FormControlLabel
              value="area"
              control={<Radio color="primary" />}
              label="Area spell"
            />
          </RadioGroup>
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Name"
            onClick={() => {
              changeEditable(EditableFields.name);
            }}
            shown={showEditable.name}
            text={currentSpell.name}
            editComponent={
              <TextField
                variant="outlined"
                placeholder="Fireball"
                value={currentSpell.name}
                onChange={onNameChange}
                inputProps={{
                  onKeyDown: (event) => {
                    if (event.key === "Enter") {
                      changeEditable(EditableFields.school);
                    }
                  },
                }}
                autoFocus
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="School"
            onClick={() => {
              changeEditable(EditableFields.school);
            }}
            shown={showEditable.school}
            text={currentSpell.school}
            editComponent={
              <Autocomplete
                options={SCHOOL_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("school")}
                value={currentSpell.school}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    onChange={onAutoCompleteTextboxChange("school")}
                    placeholder="see text"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          changeEditable(EditableFields.castingTime);
                        }
                      },
                    }}
                    autoFocus
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Levels"
            onClick={() => {
              changeEditable(EditableFields.levels);
            }}
            shown={showEditable.levels}
            text={levelsText}
            editComponent={
              <SpellLevelSelector
                spellLevels={currentSpell.spellLevels}
                onChange={onSpellLevelChange}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Casting time"
            onClick={() => {
              changeEditable(EditableFields.castingTime);
            }}
            shown={showEditable.castingTime}
            text={currentSpell.castingTime}
            editComponent={
              <Autocomplete
                options={CASTING_TIME_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("castingTime")}
                value={currentSpell.castingTime}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    onChange={onAutoCompleteTextboxChange("castingTime")}
                    placeholder="see text"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          changeEditable(EditableFields.components);
                        }
                      },
                    }}
                    autoFocus
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Casting Components"
            onClick={() => {
              changeEditable(EditableFields.components);
            }}
            shown={showEditable.components}
            text={componentsText}
            editComponent={
              <SpellComponentSelector
                components={currentSpell.components}
                onChange={(components: Components) => {
                  updateUserSpell(userSpellId, {
                    ...userSpells[userSpellId],
                    draftData: { ...currentSpell, components: components },
                  });
                }}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Range"
            onClick={() => {
              changeEditable(EditableFields.range);
            }}
            shown={showEditable.range}
            text={currentSpell.range}
            editComponent={
              <Autocomplete
                options={RANGE_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("range")}
                value={currentSpell.range}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    onChange={onAutoCompleteTextboxChange("range")}
                    placeholder="see text"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          if (isAreaSpell) {
                            changeEditable(EditableFields.area);
                          } else {
                            changeEditable(EditableFields.targets);
                          }
                        }
                      },
                    }}
                    autoFocus
                  />
                )}
              />
            }
          />
        </Grid>
        {isAreaSpell ? (
          <Grid item>
            <EditableDisplayField
              fieldName="Area"
              onClick={() => {
                changeEditable(EditableFields.area);
              }}
              shown={showEditable.area}
              text={areaText || "none"}
              editComponent={
                <Autocomplete
                  options={AREA_OPTIONS}
                  autoHighlight
                  freeSolo
                  groupBy={(option) => "Suggestions"}
                  onChange={onAutoCompleteChange("area")}
                  value={currentSpell.area}
                  renderInput={(params) => (
                    <Grid direction="column" container>
                      <Grid item>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          onChange={onAutoCompleteTextboxChange("area")}
                          placeholder="10ft cube"
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (event) => {
                              if (event.key === "Enter") {
                                changeEditable(EditableFields.duration);
                              }
                            },
                          }}
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={currentSpell.shapeable || false}
                              onChange={onCheckboxChange("shapeable")}
                              color="primary"
                            />
                          }
                          label="Shapeable"
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              }
            />
          </Grid>
        ) : (
          <Grid item>
            <EditableDisplayField
              fieldName="Targets"
              onClick={() => {
                changeEditable(EditableFields.targets);
              }}
              shown={showEditable.targets}
              text={currentSpell.targets || ""}
              editComponent={
                <Autocomplete
                  options={TARGETS_OPTIONS}
                  autoHighlight
                  freeSolo
                  groupBy={(option) => "Suggestions"}
                  onChange={onAutoCompleteChange("targets")}
                  value={currentSpell.targets}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="normal"
                      onChange={onAutoCompleteTextboxChange("targets")}
                      placeholder="one willing creature"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                          if (event.key === "Enter") {
                            changeEditable(EditableFields.duration);
                          }
                        },
                      }}
                      autoFocus
                    />
                  )}
                />
              }
            />
          </Grid>
        )}
        <Grid item>
          <EditableDisplayField
            fieldName="Duration"
            onClick={() => {
              changeEditable(EditableFields.duration);
            }}
            shown={showEditable.duration}
            text={durationText}
            editComponent={
              <Autocomplete
                options={DURATION_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("duration")}
                value={currentSpell.duration}
                renderInput={(params) => (
                  <Grid direction="column" container>
                    <Grid item>
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        onChange={onAutoCompleteTextboxChange("duration")}
                        placeholder="see text"
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (event) => {
                            if (event.key === "Enter") {
                              changeEditable(EditableFields.savingThrow);
                            }
                          },
                        }}
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={currentSpell.dismissible || false}
                            onChange={onCheckboxChange("dismissible")}
                            color="primary"
                          />
                        }
                        label="Dismissable"
                      />
                    </Grid>
                  </Grid>
                )}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Saving-throw"
            onClick={() => {
              changeEditable(EditableFields.savingThrow);
            }}
            shown={showEditable.savingThrow}
            text={currentSpell.savingThrow}
            editComponent={
              <Autocomplete
                options={SAVING_THROW_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("savingThrow")}
                value={currentSpell.savingThrow}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    placeholder="see text"
                    onChange={onAutoCompleteTextboxChange("savingThrow")}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          changeEditable(EditableFields.spellResistance);
                        }
                      },
                    }}
                    autoFocus
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Spell resistance"
            onClick={() => {
              changeEditable(EditableFields.spellResistance);
            }}
            shown={showEditable.spellResistance}
            text={currentSpell.spellResistance}
            editComponent={
              <Autocomplete
                options={SPELL_RESISTANCE_OPTIONS}
                autoHighlight
                freeSolo
                groupBy={(option) => "Suggestions"}
                onChange={onAutoCompleteChange("spellResistance")}
                value={currentSpell.spellResistance}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    onChange={onAutoCompleteTextboxChange("spellResistance")}
                    placeholder="see text"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          changeEditable(EditableFields.mindAffecting);
                        }
                      },
                    }}
                    autoFocus
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item>
          <EditableDisplayField
            fieldName="Mind affecting"
            onClick={() => {
              changeEditable(EditableFields.mindAffecting);
            }}
            shown={showEditable.mindAffecting}
            text={currentSpell.mindAffecting ? "yes" : "no"}
            editComponent={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentSpell.mindAffecting || false}
                    onChange={onCheckboxChange("mindAffecting")}
                    color="primary"
                  />
                }
                label="Mind affecting"
              />
            }
          />
        </Grid>
        <Grid item>
          <b>Description:&nbsp;</b>
          <br />
          <TextField
            className={classes.textField}
            placeholder="What does your spell do?"
            multiline
            minRows={4}
            variant="outlined"
            onChange={onDescriptionChange}
            value={currentSpell.description}
          />
        </Grid>
        <Grid direction="row" spacing={2} item container>
          <Grid xs item>
            <Button
              className={classes.saveButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                closeView();
              }}
            >
              Save draft
            </Button>
          </Grid>
          <Grid xs item>
            <Button
              className={classes.saveButton}
              color="primary"
              variant="contained"
              onClick={onSaveClick}
            >
              Publish
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = {
  updateUserSpell,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  userSpells: state.spellbookData.userSpells,
});

export default compose<FunctionComponent<Partial<EditCustomSpellProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(EditCustomSpell);
