import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default class SpellslotSelector extends Component {
    static propTypes = {
        spellLevel: PropTypes.number.isRequired,
        onChange: PropTypes.func,
        defaultValue: PropTypes.string,
    }

    render() {
        let onChangeProp = (event, value) => {
            this.props.onChange(event, value, this.props.spellLevel);
        }

        return (
            <Autocomplete
            id={"spell-slot-"+this.props.spellLevel}
            options={spellslots}
            getOptionLabel={(option) => option}
            style={{ width: 90 }}
            defaultValue={(typeof this.props.defaultValue !== 'undefined')?this.props.defaultValue:"0"}
            onChange={(typeof this.props.onChange !== 'undefined')?onChangeProp:undefined}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            />
        )
    }
}

const spellslots = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15"
  ];
