import React from "react";
import { Button, Grid, Theme } from "@material-ui/core";
import SpellLevelOverview from "./SpellLevelOverview";
import ScrollView from "./ScrollView";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
import useDeviceType from "../tools/DeviceInfo";
import { showGuidedTour } from "../actions/interfaceActions";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  mainListContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  tutorialText: {
    color: "#90979E",
    textShadow:
      "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)",
    textAlign: "center" as "center",
    padding: theme.spacing(2),
  },
  tutorialContainer: {
    height: "100%",
  },
  spacer: {
    width: theme.spacing(2),
  },
});

interface OverviewProps {
  profile: any;
  showVerticalSpellList: boolean;
  showGuidedTour: (show: boolean) => void;
  classes: ClassNameMap;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { classes } = props;

  const spellslots = props.profile.spellSlots;

  let isEmptySpellbook = true;
  isEmptySpellbook = isEmptySpellbook && !(spellslots[0].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[1].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[2].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[3].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[4].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[5].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[6].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[7].slots > 0);
  isEmptySpellbook = isEmptySpellbook && !(spellslots[8].slots > 0);

  isEmptySpellbook =
    isEmptySpellbook &&
    !(
      typeof props.profile.learnedSpells.cantrip !== "undefined" &&
      props.profile.learnedSpells.cantrip.length > 0
    );

  const { deviceType } = useDeviceType();

  const isMobile = deviceType === "MOBILE";
  const showVertical = props.showVerticalSpellList || isMobile;

  return (
    <ScrollView render={!showVertical} className={classes.root}>
      {!isEmptySpellbook ? (
        <Grid
          className={classes.mainListContainer}
          wrap="nowrap"
          direction={showVertical ? "column" : "row"}
          spacing={showVertical ? 0 : 2}
          justifyContent="flex-start"
          container
        >
          <Grid item>
            <SpellLevelOverview
              title="Cantrips"
              spellslots={0}
              usedSpellslots={0}
              spellLevel={0}
            />
          </Grid>
          {spellslots[0].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="1st level spells"
                spellslots={spellslots[0].slots}
                usedSpellslots={spellslots[0].used}
                spellLevel={1}
              />
            </Grid>
          ) : undefined}
          {spellslots[1].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="2nd level spells"
                spellslots={spellslots[1].slots}
                usedSpellslots={spellslots[1].used}
                spellLevel={2}
              />
            </Grid>
          ) : undefined}
          {spellslots[2].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="3rd level spells"
                spellslots={spellslots[2].slots}
                usedSpellslots={spellslots[2].used}
                spellLevel={3}
              />
            </Grid>
          ) : undefined}
          {spellslots[3].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="4th level spells"
                spellslots={spellslots[3].slots}
                usedSpellslots={spellslots[3].used}
                spellLevel={4}
              />
            </Grid>
          ) : undefined}
          {spellslots[4].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="5th level spells"
                spellslots={spellslots[4].slots}
                usedSpellslots={spellslots[4].used}
                spellLevel={5}
              />
            </Grid>
          ) : undefined}
          {spellslots[5].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="6th level spells"
                spellslots={spellslots[5].slots}
                usedSpellslots={spellslots[5].used}
                spellLevel={6}
              />
            </Grid>
          ) : undefined}
          {spellslots[6].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="7th level spells"
                spellslots={spellslots[6].slots}
                usedSpellslots={spellslots[6].used}
                spellLevel={7}
              />
            </Grid>
          ) : undefined}
          {spellslots[7].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="8th level spells"
                spellslots={spellslots[7].slots}
                usedSpellslots={spellslots[7].used}
                spellLevel={8}
              />
            </Grid>
          ) : undefined}
          {spellslots[8].slots > 0 ? (
            <Grid item>
              <SpellLevelOverview
                title="9th level spells"
                spellslots={spellslots[8].slots}
                usedSpellslots={spellslots[8].used}
                spellLevel={9}
              />
            </Grid>
          ) : undefined}
          <Grid className={classes.spacer} item></Grid>
        </Grid>
      ) : (
        <Grid
          className={classes.tutorialContainer}
          alignItems="center"
          justifyContent="center"
          direction="column"
          container
        >
          <Grid className={classes.tutorialText} item>
            To start, open up the settings{" "}
            <FontAwesomeIcon icon={isMobile ? faBars : faCog}></FontAwesomeIcon>{" "}
            menu in the top-right corner of the page.
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                props.showGuidedTour(true);
              }}
            >
              Show tutorial
            </Button>
          </Grid>
        </Grid>
      )}
    </ScrollView>
  );
};

const mapDispatchToProps = {
  showGuidedTour,
};

const mapStateToProps = (state: any, ownProps: OverviewProps) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  showVerticalSpellList: state.interfaceData.showVerticalSpellList,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Overview);
