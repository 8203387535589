import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Grid, Button, Radio } from "@material-ui/core";

interface StepPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  [key: string]: any;
}

function StepPanel(props: StepPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`step-panel-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

interface StepByStepViewProps {
  children: Array<React.ReactElement>;
  onClose?: () => void;
}

export default function StepByStepView(props: StepByStepViewProps) {
  const classes = useStyles();
  const [state, setState] = React.useState<{
    tabIndex: number;
    observer: IntersectionObserver | null;
  }>({
    tabIndex: 0,
    observer: null,
  });

  let views: Array<React.ReactElement> = [];
  let radios: Array<React.ReactElement> = [];
  props.children.forEach((element: React.ReactElement) => {
    radios.push(
      <Radio
        key={`radio-${views.length}`}
        checked={state.tabIndex === views.length}
        color="primary"
      />
    );

    views.push(
      <StepPanel
        key={`tab-${views.length}`}
        value={state.tabIndex}
        index={views.length}
      >
        {element}
      </StepPanel>
    );
  });

  let nextView = () => {
    if (state.tabIndex < views.length - 1) {
      setState({
        ...state,
        tabIndex: state.tabIndex + 1,
      });
    } else {
      if (typeof props.onClose == "function") {
        //setState({ ...state, tabIndex: 0 });
        props.onClose();
      }
    }
  };

  let previousView = () => {
    if (state.tabIndex > 0) {
      setState({
        ...state,
        tabIndex: state.tabIndex - 1,
      });
    }
  };

  /**
   * The following code tracks whether the component is visible, and if it is not, it will reset to step 1
   */
  const handleUnmount = () => {
    setState((state) => ({ ...state, tabIndex: 0 }));
  };

  useEffect(() => {
    return () => {
      // Clean up the observer when the component is unmounted
      if (state.observer != null) {
        state.observer.disconnect();
      }
      handleUnmount();
    };
  }, [state.observer]);

  const refCallback = (node: any) => {
    if (node) {
      if (state.observer == null) {
        let observer = new IntersectionObserver(([entry]) => {
          if (!entry.isIntersecting) {
            handleUnmount();
          }
        });

        observer.observe(node);

        setState({
          ...state,
          observer: observer,
        });
      }
    }
  };

  /**
   * Render the component
   */
  return (
    <div ref={refCallback} className={classes.root}>
      <Grid
        style={{ minHeight: "100%" }}
        direction="column"
        justifyContent="space-between"
        container
      >
        <Grid item xs>
          {views}
        </Grid>
        <Grid item>
          <Grid
            style={{ paddingBottom: 16 }}
            direction="row"
            justifyContent="center"
            container
          >
            {radios}
          </Grid>
        </Grid>
        <Grid
          style={{ paddingBottom: 24, paddingRight: 24, paddingLeft: 24 }}
          item
        >
          <Grid direction="row" justifyContent="space-between" container>
            <Grid item>
              <Button onClick={previousView} color="primary" variant="outlined">
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={nextView} color="primary" variant="contained">
                {state.tabIndex !== views.length - 1 ? "Next" : "Close"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
