//var crypto = require("crypto");
import hash from "md5";

/**
 * Returns a hash of the given object.
 * @param obj The object to hash.
 * @returns A hash of the given object.
 */
export function getHashOfObject(obj: any): string {
  var objString = JSON.stringify(obj);
  let hashStr = hash(objString);

  return hashStr;
}
