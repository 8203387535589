import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface EditableDisplayFieldProps {
  fieldName: string;
  shown: boolean;
  editComponent: React.JSX.Element;
  text: string;
  onClick: React.MouseEventHandler;
}

export default function EditableDisplayField({
  fieldName,
  shown,
  editComponent,
  text,
  onClick,
}: EditableDisplayFieldProps) {
  return (
    <>
      {shown ? (
        <div
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
          }}
        >
          <b>{fieldName}:&nbsp;</b>
          <br />
          {editComponent}
        </div>
      ) : (
        <span
          onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation();
            onClick(event);
          }}
        >
          <b>{fieldName}:&nbsp;</b>
          {text} &nbsp;
          <FontAwesomeIcon icon="pencil" />
        </span>
      )}
    </>
  );
}
