import React, { useCallback, useEffect, useState } from "react";
import { Grid, Theme, Typography, createStyles } from "@material-ui/core";
import SpellListItem from "../components/SpellListItem";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  learnSpell,
  unlearnSpell,
  toggleFavoriteSpell,
} from "../actions/spellbookActions";
import { setSpellCardPopoverState } from "../actions/interfaceActions";
import SpellInfo from "../components/SpellInfo";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { FixedSizeList } from "react-window";
import { ClassNameMap } from "@material-ui/styles";
import { State } from "../reducers";
import {
  CustomSpell,
  LearnedSpell,
  SpellbookProfileData,
} from "../reducers/spellbookData";
import { ClassOrderedSpellList, SpellList } from "../reducers/interfaceData";
import useDeviceType, { DeviceType } from "../tools/DeviceInfo";
import { ReferenceObject } from "popper.js";
import FullscreenDialog from "./FullscreenDialog";
import { combineSpellLists } from "../tools/UserSpellTools";
import TourManager, { TourEvents } from "../tools/TourManager";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      //margin: theme.spacing(2),
      width: "100%",
      overflow: "hidden",
    },
    text: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    searchField: {
      width: "100%",
    },
    spellList: {
      backgroundColor: theme.palette.background.paper,
    },
    popper: {
      width: 500,
      padding: theme.spacing(2),
    },
    wrapper: {
      height: "100%",
      width: "100%",
      overflow: "hidden",
      position: "relative",
      display: "inline-block",
    },
    innerWrapper: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0 /* follow the parent's edges */,
    },
  });

interface SpellListContainerProps {
  classes: ClassNameMap;
  profile: SpellbookProfileData;
  spellCardPopover: {
    isOpen: boolean;
    spell: string | null;
  };
  filteredSpells: ClassOrderedSpellList;
  spellList: SpellList;
  drawerReference: ReferenceObject | null;
  showSpellDrawer: boolean;
  learnSpell: (
    spellLevel: number,
    spellClass: string,
    spellId: string | CustomSpell
  ) => void;
  unlearnSpell: (
    spellLevel: number,
    spellClass: string,
    spellId: string | CustomSpell
  ) => void;
  toggleFavoriteSpell: (
    spellId: string | CustomSpell,
    className: string,
    level: number
  ) => void;
  setSpellCardPopoverState: (isOpen: boolean, spellId: string | null) => void;
}

function SpellListContainer(props: SpellListContainerProps) {
  let { classes } = props;

  const [state, setstate] = useState<{
    isOpen: boolean;
    listHeight: number;
    wrapperElement: HTMLDivElement | null;
  }>({
    isOpen: false,
    listHeight: 0,
    wrapperElement: null,
  });

  let anchorElement: ReferenceObject = {
    clientWidth: 10,
    clientHeight: 10,
    getBoundingClientRect: () => {
      let output = {
        bottom: window.innerHeight / 2,
        top: window.innerHeight / 2,
        left: window.innerWidth - 460,
        right: 450,
        height: 0,
        width: 0,
        x: 0,
        y: 0,
      };
      return { ...output, toJSON: () => output };
    },
  };

  if (props.drawerReference) {
    anchorElement = props.drawerReference;
  }

  //let wrapperRef = useRef<HTMLDivElement>(null);
  let wrapperRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setstate((state) => ({
        ...state,
        listHeight: node.clientHeight,
        wrapperElement: node,
      }));
    }
  }, []);

  let { deviceType, height } = useDeviceType();
  let isMobile = deviceType === DeviceType.MOBILE;

  const onLearnClick =
    (spellLevel: number, spellClass: string, spellId: string | CustomSpell) =>
    (event: React.MouseEvent<any>) => {
      props.learnSpell(spellLevel, spellClass, spellId);
    };

  const onUnlearnClick =
    (spellLevel: number, spellClass: string, spellId: string | CustomSpell) =>
    (event: React.MouseEvent<any>) => {
      props.unlearnSpell(spellLevel, spellClass, spellId);
    };

  const getItem = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => <div style={style}>{itemList[index]}</div>;

  const openPopover = (spellId: string) => (event: React.MouseEvent<any>) => {
    props.setSpellCardPopoverState(true, spellId);
  };

  const handlePopoverClose = () => {
    if (!isMobile) {
      props.setSpellCardPopoverState(false, null);
    }
  };

  const handleDialogClose = () => {
    props.setSpellCardPopoverState(false, null);
  };

  let itemList: Array<React.JSX.Element> = [];

  let spellListToShow = props.filteredSpells;

  let computedWidth = state.wrapperElement?.offsetWidth
    ? state.wrapperElement?.offsetWidth
    : 0;

  // We calculate the maximum with a title of an item may have by looking at the container width and substracting offsets
  let computedMaxItemTitleWidth: number | undefined = undefined;
  if (computedWidth !== 0) {
    // computedMaxItemTitleWidth = width - school icon - spelllevel text - favorite icon - learn button - padding
    computedMaxItemTitleWidth = computedWidth - 32 - 60 - 40 - 72;
  }

  Object.entries(spellListToShow).forEach(([className, spellByLevel]) => {
    itemList.push(
      <Grid
        key={className}
        style={{ height: "100%" }}
        alignContent="center"
        container
      >
        <Grid item>
          <Typography className={classes.text} variant="h6">
            {className}
          </Typography>
        </Grid>
      </Grid>
    );

    Object.entries(spellByLevel).forEach(([levelString, spells]) => {
      let level = parseInt(levelString.split("-")[1]);

      spells.forEach((spellId) => {
        // If it is a custom spell it is an object instead of a string
        if (typeof spellId === "object") {
          let isLearned = false;
          let spellLevelLabels = [
            "cantrip",
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh",
            "eighth",
            "ninth",
          ];

          let learnedSpellList: Array<LearnedSpell> | undefined =
            props.profile.learnedSpells[spellLevelLabels[level]];

          if (typeof learnedSpellList !== "undefined") {
            learnedSpellList.forEach((element) => {
              if (
                element.spellId === spellId.spellId &&
                element.className === className &&
                element.isPrepared === spellId.isPrepared
              ) {
                isLearned = true;
              }
            });
          }

          let isFavorite = false;
          if (typeof props.profile.favoriteSpells[className] !== "undefined") {
            // Check the favorites if the custom spell has been added to the favorites list
            isFavorite =
              props.profile.favoriteSpells[className]["lvl-" + level].findIndex(
                (spell) => {
                  if (typeof spell === "string") return false;
                  let customSpell = spell as CustomSpell;
                  let isTheSameSpell =
                    customSpell.isPrepared === spellId.isPrepared &&
                    customSpell.spellId === spellId.spellId;

                  return isTheSameSpell;
                }
              ) > -1;
          }

          itemList.push(
            <SpellListItem
              spell={props.spellList[spellId.spellId]}
              onLearnClick={onLearnClick(level, className, spellId)}
              onUnlearnClick={onUnlearnClick(level, className, spellId)}
              onHover={openPopover(spellId.spellId)}
              onHoverExit={isMobile ? () => {} : handlePopoverClose}
              onFavoriteClick={() => {
                props.toggleFavoriteSpell(spellId, className, level);
              }}
              isFavorite={isFavorite}
              spellLevel={level}
              isLearned={isLearned}
              maxTitleWidth={computedMaxItemTitleWidth}
            />
          );
        } else if (
          typeof spellId !== "undefined" &&
          typeof props.spellList[spellId] !== "undefined"
        ) {
          let isLearned = false;
          let spellLevelLabels = [
            "cantrip",
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh",
            "eighth",
            "ninth",
          ];

          let learnedSpellList =
            props.profile.learnedSpells[spellLevelLabels[level]];
          if (typeof learnedSpellList !== "undefined") {
            learnedSpellList.forEach((element) => {
              if (
                element.spellId === spellId &&
                element.className === className
              ) {
                isLearned = true;
              }
            });
          }

          let isFavorite = false;
          if (typeof props.profile.favoriteSpells[className] !== "undefined") {
            isFavorite =
              props.profile.favoriteSpells[className]["lvl-" + level].includes(
                spellId
              );
          }

          itemList.push(
            <SpellListItem
              spell={props.spellList[spellId]}
              onLearnClick={onLearnClick(level, className, spellId)}
              onUnlearnClick={onUnlearnClick(level, className, spellId)}
              onHover={openPopover(spellId)}
              onHoverExit={isMobile ? () => {} : handlePopoverClose}
              onFavoriteClick={() => {
                props.toggleFavoriteSpell(spellId, className, level);
              }}
              isFavorite={isFavorite}
              spellLevel={level}
              isLearned={isLearned}
              maxTitleWidth={computedMaxItemTitleWidth}
            />
          );
        }
      });
    });
  });

  let spellViewer = isMobile ? (
    <FullscreenDialog
      open={props.spellCardPopover.isOpen}
      handleClose={handleDialogClose}
      title={
        props.spellCardPopover.spell !== null
          ? props.spellList[props.spellCardPopover.spell].name
          : "Undefined"
      }
    >
      <SpellInfo spellId={props.spellCardPopover.spell} />
    </FullscreenDialog>
  ) : (
    <Popper
      style={{ zIndex: 1400 }}
      open={props.spellCardPopover.isOpen && props.showSpellDrawer}
      anchorEl={anchorElement}
      transition
      placement="left"
      modifiers={{
        offset: {
          enabled: true,
          offset: "0, 48",
        },
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={400}>
          <Paper style={{ maxHeight: height }} className={classes.popper}>
            <Typography variant="h6">
              {props.spellCardPopover.spell !== null
                ? props.spellList[props.spellCardPopover.spell].name
                : "Undefined"}
            </Typography>
            {props.spellCardPopover.spell !== null ? (
              <SpellInfo spellId={props.spellCardPopover.spell} />
            ) : (
              "Undefined"
            )}
          </Paper>
        </Fade>
      )}
    </Popper>
  );

  // When the tour needs the spell details to be closed, we close them here
  let tourManager = TourManager.getInstance();
  useEffect(() => {
    let subscription = tourManager.subscribe((event, item) => {
      if (event === TourEvents.CLOSE_REQUEST && item === "spell-details") {
        props.setSpellCardPopoverState(false, null);
      }
    });
    return () => {
      tourManager.unsubscribe(subscription);
    };
  }, [tourManager, props]);

  return (
    <div ref={wrapperRef} className={`${classes.wrapper} tour-spell-items`}>
      <div className={classes.innerWrapper}>
        <FixedSizeList
          height={
            state.wrapperElement !== null
              ? state.wrapperElement.offsetHeight
              : 0
          }
          width={"100%"}
          itemSize={40}
          itemCount={itemList.length}
          style={{ overflowX: "hidden" }}
        >
          {getItem}
        </FixedSizeList>
        {spellViewer}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  learnSpell,
  unlearnSpell,
  setSpellCardPopoverState,
  toggleFavoriteSpell,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellCardPopover: state.interfaceData.spellCardPopover,
  filteredSpells: state.interfaceData.filteredSpells,
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
});

export default compose<
  React.FunctionComponent<Partial<SpellListContainerProps>>
>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellListContainer);
