import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  IconButton,
  Theme,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpellSchoolIcons from "../themes/SpellSchoolIcons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ClassNameMap } from "@material-ui/styles";
import { Spell } from "../reducers/interfaceData";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useDeviceType from "../tools/DeviceInfo";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  spellNameText: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  spellClassText: {
    color: theme.palette.text.disabled,
    fontSize: 12,
  },
  favoriteIcon: {
    padding: 4,
  },
});

const spellLevelLabels = [
  "Cantrip",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
];

const favoriteInactive = "#ddd";
const favoriteActive = "#f39c12";

interface SpellListItemProps {
  classes: ClassNameMap;
  spell: Spell;
  spellLevel: number;
  onLearnClick: (e: React.MouseEvent<any>) => void;
  onUnlearnClick: (e: React.MouseEvent<any>) => void;
  onHover: (e: React.MouseEvent<any>) => void;
  onHoverExit: (e: React.MouseEvent<any>) => void;
  onFavoriteClick: (e: React.MouseEvent<any>) => void;
  isFavorite: boolean;
  isLearned: boolean;
  maxTitleWidth?: number;
}

function SpellListItem(props: SpellListItemProps) {
  let { classes, spell } = props;

  let { deviceType } = useDeviceType();

  if (typeof spell == "undefined") {
    return <></>;
  }

  let isMobile = deviceType === "MOBILE";

  return (
    <Grid direction="row" alignItems="center" spacing={1} container>
      <Grid item xs>
        <Grid direction="row" spacing={1} container>
          <Grid style={{ width: 32, textAlign: "center" }} item>
            {typeof SpellSchoolIcons[spell.school] !== "undefined" ? (
              <FontAwesomeIcon
                icon={SpellSchoolIcons[spell.school].icon as IconProp}
                color={SpellSchoolIcons[spell.school].color}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item>
            <Typography
              className={`${classes.spellNameText}`}
              noWrap
              style={{
                maxWidth: props.maxTitleWidth ? props.maxTitleWidth : undefined,
              }}
              onClick={props.onHover}
              onMouseEnter={!isMobile ? props.onHover : undefined}
              onMouseLeave={!isMobile ? props.onHoverExit : undefined}
            >
              {spell.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.spellClassText}>
              ({spellLevelLabels[props.spellLevel]})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          className={classes.favoriteIcon}
          onClick={props.onFavoriteClick}
        >
          <FontAwesomeIcon
            color={props.isFavorite ? favoriteActive : favoriteInactive}
            icon={faStar as IconProp}
          ></FontAwesomeIcon>
        </IconButton>
      </Grid>
      <Grid item>
        <Button
          onClick={props.isLearned ? props.onUnlearnClick : props.onLearnClick}
          variant={props.isLearned ? "outlined" : "contained"}
          color="primary"
          size="small"
        >
          {props.isLearned ? "Unlearn" : "Learn"}
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SpellListItem);
