import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { State } from "../reducers";

interface SyncIndicatorProps {
  classes: ClassNameMap;
  lastCloudSync: number;
  isCloudSyncing: boolean;
  cloudSyncCountdown: number;
}

const styles = (theme: any) => ({
  syncTime: {
    textAlign: "center" as "center",
    color: "#90979E",
    textShadow:
      "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)",
  },
  progress: {
    width: "100%",
  },
});

export const SyncIndicator: React.FC<SyncIndicatorProps> = (
  props: SyncIndicatorProps
) => {
  const { classes } = props;

  let contents = (
    <span>
      {"Last cloud sync at " +
        new Date(props.lastCloudSync).toLocaleTimeString()}
    </span>
  );
  if (props.isCloudSyncing || props.cloudSyncCountdown === 0) {
    contents = (
      <span>
        Cloud syncing <CircularProgress size="1rem" />
      </span>
    );
  } else if (props.cloudSyncCountdown <= 5) {
    contents = <div>Cloud syncing in {props.cloudSyncCountdown}...</div>;
  }

  return <div className={classes.syncTime}>{contents}</div>;
};

const mapDispatchToProps = {};

const mapStateToProps = (
  state: State,
  ownProps: Partial<SyncIndicatorProps>
) => ({
  ...ownProps,
  lastCloudSync: state.interfaceData.lastCloudSync,
  isCloudSyncing: state.interfaceData.isCloudSyncing,
  cloudSyncCountdown: state.interfaceData.cloudSyncCountdown,
});

export default compose<React.FC<Partial<SyncIndicatorProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SyncIndicator);
