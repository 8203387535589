import React from "react";
import { Grid, Theme, Typography, createStyles } from "@material-ui/core";
import SpellslotIndicator from "../components/SpellslotIndicator";
import { withStyles } from "@material-ui/core/styles";
import SpellCard from "./SpellCard";
import {
  setSpellSlotUsed,
  setSpellSlotFree,
  adjustSpellPreparations,
} from "../actions/spellbookActions";

import { compose } from "redux";
import { connect } from "react-redux";
import useDeviceType, { DeviceType } from "../tools/DeviceInfo";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { State } from "../reducers";
import { SpellList } from "../reducers/interfaceData";
import { LearnedSpell } from "../reducers/spellbookData";
import { combineSpellLists } from "../tools/UserSpellTools";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rootVertical: {
      width: "100%",
    },
    rootVerticalNonFirst: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    text: {
      color: theme.palette.secondary.main,
    },
    spellslotsArea: {
      minHeight: 50,
      width: "100%",
    },
    gridItem: {
      width: "100%",
    },
  });

interface SpellLevelOverviewProps {
  classes: ClassNameMap;
  title: string;
  spellslots: number;
  usedSpellslots: number;
  spellLevel: number;
  spellList: SpellList;
  showVertical: boolean;
  learnedSpells: { [levelName: string]: Array<LearnedSpell> };
  spellCardWidth?: number;
  adjustSpellPreparations: (
    level: number,
    className: string,
    spellId: string,
    adjustment: number
  ) => void;
  setSpellSlotUsed: (level: number) => void;
  setSpellSlotFree: (level: number) => void;
}

export function SpellLevelOverview(props: SpellLevelOverviewProps) {
  let { classes, title, spellslots, usedSpellslots } = props;

  let spellLevelLabels = [
    "cantrip",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
  ];

  let preparedCasters = [
    "Wizard",
    "Druid",
    "Cleric",
    "Shaman",
    "Alchemist",
    "Warpriest",
    "Investigator",
    "Magus",
  ];

  let spellCards: Array<React.ReactElement> = [];

  let { deviceType } = useDeviceType();

  let showVertical = props.showVertical || deviceType === DeviceType.MOBILE;

  if (
    typeof props.learnedSpells[spellLevelLabels[props.spellLevel]] !==
    "undefined"
  ) {
    props.learnedSpells[spellLevelLabels[props.spellLevel]].forEach(
      (element) => {
        if (typeof props.spellList[element.spellId] !== "undefined") {
          let preparedSpells: number | undefined = undefined;

          if (
            preparedCasters.indexOf(element.className) > -1 ||
            element.isPrepared
          ) {
            if (typeof element.preparedSpells !== "undefined") {
              preparedSpells = element.preparedSpells;
            } else {
              preparedSpells = 0;
            }
          }

          spellCards.push(
            <Grid key={element.spellId} item>
              <SpellCard
                spellId={element.spellId}
                spellName={props.spellList[element.spellId].name}
                spellSchool={props.spellList[element.spellId].school}
                spellLevel={props.spellLevel}
                onCastClick={() => {
                  if (typeof preparedSpells !== "undefined") {
                    props.adjustSpellPreparations(
                      props.spellLevel,
                      element.className,
                      element.spellId,
                      -1
                    );
                  } else {
                    props.setSpellSlotUsed(props.spellLevel);
                  }
                }}
                preparedSpells={preparedSpells}
                preparedSpellsAdjuster={(adjustment: number) => {
                  props.adjustSpellPreparations(
                    props.spellLevel,
                    element.className,
                    element.spellId,
                    adjustment
                  );
                }}
                showVertical={showVertical}
              />
            </Grid>
          );
        }
      }
    );
  }

  return (
    <Grid
      className={
        showVertical
          ? title === "Cantrips"
            ? classes.rootVertical
            : classes.rootVerticalNonFirst
          : classes.root
      }
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      container
    >
      <Grid item>
        <Typography variant="h5" className={classes.text}>
          {title}
        </Typography>
      </Grid>
      {showVertical && title === "Cantrips" ? undefined : (
        <Grid className={classes.spellslotsArea} item>
          <SpellslotIndicator
            spellslots={spellslots}
            usedSlots={usedSpellslots}
            removeSpellslot={() => {
              props.setSpellSlotUsed(props.spellLevel);
            }}
            addSpellslot={() => {
              props.setSpellSlotFree(props.spellLevel);
            }}
          ></SpellslotIndicator>
        </Grid>
      )}
      <Grid
        className={showVertical ? classes.rootVertical : classes.gridItem}
        style={
          !showVertical && props.spellCardWidth
            ? { width: props.spellCardWidth }
            : {}
        }
        item
      >
        <Grid
          spacing={1}
          direction={"column"}
          className={showVertical ? classes.rootVertical : undefined}
          container
        >
          {spellCards}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  setSpellSlotUsed,
  setSpellSlotFree,
  adjustSpellPreparations,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  learnedSpells:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current
      .learnedSpells,
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
  showVertical: state.interfaceData.showVerticalSpellList,
  spellCardWidth: state.interfaceData.spellCardWidth
    ? state.interfaceData.spellCardWidth
    : 350,
});

export default compose<React.FC<Partial<SpellLevelOverviewProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellLevelOverview);
