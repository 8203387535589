import {
  faStar,
  faPaw,
  faHeart,
  faGem,
  faShieldAlt,
  faCloud,
  faSkull,
  /*faStarAndCrescent,*/ faFire,
  faEyeSlash,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

let icons: { [schoolName: string]: { icon: IconDefinition; color: string } } = {
  Conjuration: {
    icon: faPaw,
    color: "#27ae60",
  },
  Enchantment: {
    icon: faHeart,
    color: "#e74c3c",
  },
  Transmutation: {
    icon: faGem,
    color: "#8e44ad",
  },
  Abjuration: {
    icon: faShieldAlt,
    color: "#f39c12",
  },
  Divination: {
    icon: faCloud,
    color: "#3498db",
  },
  Necromancy: {
    icon: faSkull,
    color: "#000000",
  },
  Universal: {
    icon: faStar,
    color: "#e74c3c",
  },
  Evocation: {
    icon: faFire,
    color: "#d35400",
  },
  Illusion: {
    icon: faEyeSlash,
    color: "#7f8c8d",
  },
};

export default icons;
