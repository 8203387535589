import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Theme } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { downloadSpell } from "../actions/interfaceActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { State } from "../reducers";
import { SpellList } from "../reducers/interfaceData";
import { combineSpellLists } from "../tools/UserSpellTools";

const MAX_SPELL_DOWNLOAD_TIME = 10000;

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  text: {
    fontWeight: 500,
  },
});

interface SpellInfoProps {
  spellId: string | null;
  spellList: SpellList;
  downloadSpell: (spellId: string) => void;
}

function SpellInfo(props: SpellInfoProps) {
  let content: React.ReactNode;

  if (props.spellId === null) {
    content = <> </>;
  } else {
    const spell = props.spellList[props.spellId];

    if (typeof spell == "undefined") {
      content = <> </>;
    } else if (
      typeof spell.isDownloading === "undefined" &&
      !spell.isUserSpell
    ) {
      content = (
        <Grid spacing={1} direction="column" container>
          <Grid item>
            <Grid
              direction="column"
              alignItems="center"
              justifyContent="center"
              container
            >
              <Grid item>
                <CircularProgress size={60} color="primary" />
              </Grid>
              <Grid item>
                <b>Loading...</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );

      props.downloadSpell(props.spellId);
    } else if (
      spell.isDownloading &&
      (typeof spell.downloadStartDate !== "number" ||
        spell.downloadStartDate + MAX_SPELL_DOWNLOAD_TIME < Date.now())
    ) {
      content = (
        <Grid spacing={1} direction="column" container>
          <Grid item>
            <Grid
              direction="column"
              alignItems="center"
              justifyContent="center"
              container
            >
              <Grid item>
                <CircularProgress size={60} color="primary" />
              </Grid>
              <Grid item>
                <b>Loading...</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );

      props.downloadSpell(props.spellId);
    } else {
      if (props.spellList[props.spellId].isDownloading) {
        content = (
          <Grid item>
            <Grid
              direction="column"
              alignItems="center"
              justifyContent="center"
              container
            >
              <Grid item>
                <CircularProgress size={60} color="primary" />
              </Grid>
              <Grid item>
                <b>Loading...</b>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        content = [
          <Grid key={"cast-time"} item>
            <b>Casting time: </b>
            {spell.casting_time_text}
          </Grid>,
          <Grid key={"cast-comp"} item>
            <b>Casting components: </b>
            {spell.components_text}
          </Grid>,
          <Grid key={"range"} item>
            <b>Range: </b>
            {spell.range_text}
          </Grid>,
          ...(spell.targets_text !== ""
            ? [
                <Grid key={"target"} item>
                  <b>Target: </b>
                  {spell.targets_text}
                </Grid>,
              ]
            : []),
          ...(spell.area_text !== ""
            ? [
                <Grid key={"area"} item>
                  <b>Area: </b>
                  {spell.area_text}
                </Grid>,
              ]
            : []),
          <Grid key={"duration"} item>
            <b>Duration: </b>
            {spell.duration_text}
          </Grid>,
          <Grid key={"saving-throw"} item>
            <b>Saving-throw: </b>
            {spell.saving_throw}
          </Grid>,
          ...(spell.spell_resistance !== ""
            ? [
                <Grid key={"resistence"} item>
                  <b>Spell Resistence: </b>
                  {spell.spell_resistance}
                </Grid>,
              ]
            : []),
          <Grid key={"description"} item>
            <b>Description: </b>
            <br />
            {spell.description
              ? spell.description.replace(/(?:\r\n|\r|\n)/g, "<br>")
              : undefined}
          </Grid>,
        ];
      }
    }
  }

  return (
    <Grid spacing={1} direction="column" container>
      {content}
    </Grid>
  );
}

const mapDispatchToProps = {
  downloadSpell,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
});

export default compose<React.FunctionComponent<Partial<SpellInfoProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellInfo);
