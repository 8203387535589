import React from "react";
import useDeviceType from "../tools/DeviceInfo";

interface ScrollViewProps {
  className?: string;
  children: React.ReactNode;
  render?: boolean;
}

export default function ScrollView(props: ScrollViewProps) {
  let { children } = props;
  let { height } = useDeviceType();

  let scrollViewHeight = height - 64;
  return (
    <React.Fragment>
      {props.render ? (
        <div
          className={props.className}
          style={{
            overflow: "auto",
            width: "100%",
            height: scrollViewHeight,
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
}
