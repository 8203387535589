import { v4 } from "uuid";

export enum TourEvents {
  CLICKED = "clicked",
  CLOSE_REQUEST = "close_request",
}

export class TourManager {
  private static instance: TourManager;

  private subscribers: {
    [subId: string]: (event: TourEvents, target: string) => void;
  } = {};

  private constructor() {
    // Private constructor to prevent instantiation from outside the class
  }

  public static getInstance(): TourManager {
    if (!TourManager.instance) {
      TourManager.instance = new TourManager();
    }
    return TourManager.instance;
  }

  public subscribe(callback: (event: TourEvents, target: string) => void) {
    let uuid = v4();
    this.subscribers[uuid] = callback;
    return uuid;
  }

  public unsubscribe(uuid: string) {
    delete this.subscribers[uuid];
  }

  public clickItem(item: string) {
    for (const value of Object.values(this.subscribers)) {
      value(TourEvents.CLICKED, item);
    }
  }

  public requestClose(item: string) {
    for (const value of Object.values(this.subscribers)) {
      value(TourEvents.CLOSE_REQUEST, item);
    }
  }
}

export default TourManager;
