import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Components } from "../containers/CustomSpells/CustomSpellInterface";

interface SpellComponentSelectorProps {
  components: Components;
  onChange: (components: Components) => void;
}

export default function SpellComponentSelector({
  components,
  onChange,
}: SpellComponentSelectorProps) {
  let onVerbalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, verbal: event.target.checked });
  };

  let onSomaticChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, somatic: event.target.checked });
  };

  let onMaterialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, material: event.target.checked });
  };

  let onFocusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, focus: event.target.checked });
  };

  let onDivineFocusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, divineFocus: event.target.checked });
  };

  let onMaterialTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...components, materialComponents: event.target.value });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={components.verbal}
                onChange={onVerbalChange}
                name="verbal"
                color="primary"
              />
            }
            label="Verbal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={components.somatic}
                onChange={onSomaticChange}
                name="somatic"
                color="primary"
              />
            }
            label="Somatic"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={components.material}
                onChange={onMaterialChange}
                name="material"
                color="primary"
              />
            }
            label="Material"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={components.focus}
                onChange={onFocusChange}
                name="focus"
                color="primary"
              />
            }
            label="Focus"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={components.divineFocus}
                onChange={onDivineFocusChange}
                name="divineFocus"
                color="primary"
              />
            }
            label="Divine focus"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <TextField
          label="Material description"
          style={{ width: "100%" }}
          variant="outlined"
          onChange={onMaterialTextChange}
          placeholder="25g worth of precious gems"
          value={components.materialComponents}
        />
      </Grid>
    </Grid>
  );
}
