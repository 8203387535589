import React from "react";
import { Grid, Radio } from "@material-ui/core";

interface SpellslotIndicatorProps {
  spellslots: number;
  usedSlots: number;
  addSpellslot: () => void;
  removeSpellslot: () => void;
}

const SpellslotIndicator: React.FunctionComponent<SpellslotIndicatorProps> = (
  props: SpellslotIndicatorProps
) => {
  let { spellslots, usedSlots } = props;
  const slots = [];

  for (let i = 0; i < spellslots; i++) {
    let isChecked = i < usedSlots;

    let onClick = () => {};
    if (props.addSpellslot && props.removeSpellslot) {
      if (isChecked) {
        onClick = props.addSpellslot;
      } else {
        onClick = props.removeSpellslot;
      }
    }

    slots.push(
      <Grid key={i} item>
        <Radio
          onClick={onClick}
          checked={isChecked}
          name="radio-button"
          color="secondary"
        />
      </Grid>
    );
  }

  return (
    <Grid justifyContent="center" alignItems="center" container>
      {slots}
    </Grid>
  );
};

export default SpellslotIndicator;
