import React, { useState, FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomSpell, UserSpell } from "./CustomSpellInterface";
import { connect } from "react-redux";
import { Grid, Button, Theme, withStyles, Typography } from "@material-ui/core";
import { State } from "../../reducers";
import { compose } from "redux";
import {
  deleteUserSpell,
  updateUserSpell,
} from "../../actions/spellbookActions";
import { v4 as uuidv4 } from "uuid";
import EditCustomSpell from "./EditCustomSpell";
import SpellDraftCard from "../../components/SpellDraftCard";
import { ClassNameMap } from "@material-ui/styles";
import { isLoggedIn } from "../../tools/Account";

interface CustomSpellOverviewProps {
  updateUserSpell: (userSpellId: string, spell: UserSpell) => void;
  deleteUserSpell: (userSpellId: string) => void;
  openView: (
    title: string,
    element: React.JSX.Element
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  userSpells: { [userSpellId: string]: UserSpell };
  classes: ClassNameMap;
}

const EMPTY_SPELL: CustomSpell = {
  name: "My custom spell",
  spellLevels: {},
  school: "Universal",
  castingTime: "Full-Round Action",
  range: "touch",
  components: {
    verbal: true,
    somatic: true,
    material: false,
    focus: false,
    divineFocus: false,
    materialComponents: "",
  },
  targets: "one creature",
  area: "10ft cube",
  duration: "1 minute/level",
  dismissible: false,
  savingThrow: "none",
  spellResistance: "no",
  mindAffecting: false,
  shapeable: false,
  materialText: "",
  shortDescription: "",
  description: "",
};

let styles = (theme: Theme) => ({
  button: {
    width: "100%",
    height: 48,
  },
  spellcard: {
    marginBottom: theme.spacing(2),
  },
  spellDraftCardItem: {
    maxWidth: "100%",
  },
});

function CustomSpellOverview({
  openView,
  deleteUserSpell,
  updateUserSpell,
  userSpells,
  classes,
}: CustomSpellOverviewProps) {
  const [state, setstate] = useState({
    showCreateSpell: false,
  });

  /**
   * Creates a new spell draft and opens the edit view for it.
   */
  let createFromScratch = (event: React.MouseEvent<HTMLElement>) => {
    let userSpellId = uuidv4();
    updateUserSpell(userSpellId, { draftData: EMPTY_SPELL });
    openView(
      "Edit custom spell",
      <EditCustomSpell userSpellId={userSpellId} />
    )(event);
  };

  if (typeof userSpells === "undefined") {
    userSpells = {};
  }

  let userSpellDraftCards = [];
  let userSpellCards = [];
  for (let [userSpellId, userSpell] of Object.entries(userSpells)) {
    if (typeof userSpell.draftData != "undefined") {
      userSpellDraftCards.push(
        <Grid key={userSpellId} className={classes.spellDraftCardItem} item>
          <SpellDraftCard
            className={classes.spellcard}
            spellName={userSpell.draftData.name}
            spellSchool={userSpell.draftData.school}
            lastModified={new Date(userSpell.lastModified || new Date())}
            deleteDraft={() => {
              updateUserSpell(userSpellId, {
                ...userSpell,
                draftData: undefined,
              });
            }}
            onClick={openView(
              "Edit custom spell",
              <EditCustomSpell userSpellId={userSpellId} />
            )}
          />
        </Grid>
      );
    }

    if (typeof userSpell.data != "undefined") {
      userSpellCards.push(
        <Grid key={userSpellId} className={classes.spellDraftCardItem} item>
          <SpellDraftCard
            className={classes.spellcard}
            spellName={userSpell.data.name}
            spellSchool={userSpell.data.school}
            lastModified={new Date(userSpell.lastModified || new Date())}
            deleteDraft={() => {
              deleteUserSpell(userSpellId);
            }}
            onClick={openView(
              "Edit custom spell",
              <EditCustomSpell userSpellId={userSpellId} />
            )}
          />
        </Grid>
      );
    }
  }

  return (
    <>
      {isLoggedIn() ? (
        <>
          {state.showCreateSpell ? (
            <Grid direction="column" spacing={3} alignItems="center" container>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createFromScratch}
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;&nbsp;&nbsp;Create from scratch
                </Button>
              </Grid>
              <Grid item>
                <Button disabled variant="contained" color="primary">
                  <FontAwesomeIcon icon="book" />
                  &nbsp;&nbsp;&nbsp;Create from template
                </Button>
              </Grid>
              <Grid item>
                <Button disabled variant="contained" color="primary">
                  <FontAwesomeIcon icon="wand-magic-sparkles" />
                  &nbsp;&nbsp;&nbsp;AI assisted creation
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setstate({ ...state, showCreateSpell: false })}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;&nbsp;&nbsp;Go back to list
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid direction="column" container>
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => setstate({ ...state, showCreateSpell: true })}
                >
                  Create new spell
                </Button>
              </Grid>
              {userSpellDraftCards.length > 0 ? (
                <Grid item container spacing={2} direction="column">
                  <Grid item>
                    <h2>Drafts</h2>
                  </Grid>
                  {userSpellDraftCards}
                </Grid>
              ) : undefined}
              <Grid item container direction="column">
                <Grid item>
                  <h2>Your custom spells</h2>
                </Grid>
                {userSpellCards.length > 0 ? (
                  userSpellCards
                ) : (
                  <Grid item>
                    You don't have any custom spells yet. Click the button above
                    to create one!
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Typography align="center" color="error" variant="h6">
          You need to be logged in to create custom spells.
        </Typography>
      )}
    </>
  );
}

const mapDispatchToProps = {
  deleteUserSpell,
  updateUserSpell,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  userSpells: state.spellbookData.userSpells,
});

export default compose<FunctionComponent<Partial<CustomSpellOverviewProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomSpellOverview);
