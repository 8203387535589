import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";
import {
  Button,
  Grid,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import DevicePopup from "./DevicePopup";
import StepByStepView from "./StepByStepView";
import SpellbookSettings from "./Settings/SpellbookSettings";
import {
  showSpellbookGuide,
  showGuidedTour,
} from "../actions/interfaceActions";
import SpellSlotSettings from "./Settings/SpellSlotSettings";
import useDeviceType from "../tools/DeviceInfo";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      //margin: theme.spacing(2),
    },
  });

interface SpellbookGuideProps {
  show?: boolean;
  showSpellbookGuide: (show: boolean) => void;
  showGuidedTour: (show: boolean) => void;
}

export const SpellbookGuide: React.FunctionComponent<SpellbookGuideProps> = (
  props: SpellbookGuideProps
) => {
  let { deviceType } = useDeviceType();
  let isMobile = deviceType === "MOBILE";

  return (
    <DevicePopup title="Spellbook setup" isOpen={props.show}>
      <StepByStepView onClose={() => props.showSpellbookGuide(!props.show)}>
        <div>
          <SpellbookSettings />
        </div>
        <div>
          <SpellSlotSettings />
        </div>
        <div>
          <Grid
            direction="column"
            justifyContent="center"
            alignContent="center"
            spacing={3}
            container
          >
            <Grid style={{ textAlign: "center" }} item>
              <Typography variant={isMobile ? "h2" : "h1"} color="primary">
                You're set!
              </Typography>
              <Typography variant="body1">
                Use the guided tour below to see all features the app has to
                offer or open up the spell list from the menu and start learning
                some spells for your character! Happy adventuring!
              </Typography>
            </Grid>
            <Grid style={{ textAlign: "center" }} item>
              <Typography variant="body1">
                <i>
                  (You can always access the guided tour from the settings menu
                  by clicking the 'Changelog&nbsp;&&nbsp;Help' button.)
                </i>
              </Typography>
            </Grid>
            <Grid style={{ textAlign: "center" }} item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.showSpellbookGuide(false);
                  props.showGuidedTour(true);
                }}
              >
                Take a guided tour
              </Button>
            </Grid>
          </Grid>
        </div>
      </StepByStepView>
    </DevicePopup>
  );
};

const mapDispatchToProps = {
  showSpellbookGuide,
  showGuidedTour,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  show: state.interfaceData.showSpellbookGuide,
});

export default compose<React.FunctionComponent<Partial<SpellbookGuideProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellbookGuide);
