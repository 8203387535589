import React, { useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faBars } from "@fortawesome/free-solid-svg-icons";
import { theme as defaultTheme, theme } from "./themes/theme";
import Overview from "./containers/Overview";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  retrieveSpells,
  retrieveCategorizedSpells,
  setTutorialViewed,
  showGuidedTour,
} from "./actions/interfaceActions";
import { resetSpellSlots } from "./actions/spellbookActions";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleLoginComponent from "./components/GoogleLoginComponent";

import { isLoggedIn } from "./tools/Account";
import SyncIndicator from "./components/SyncIndicator";
import useDeviceType from "./tools/DeviceInfo";
import MobileMenu from "./containers/MobileMenu";
import Changelog from "./containers/Changelog";
import SpellbookGuide from "./containers/SpellbookGuide";
import { combineSpellLists } from "./tools/UserSpellTools";
import ReactJoyride from "react-joyride";
import TourManager from "./tools/TourManager";

const themeData = JSON.parse(JSON.stringify(defaultTheme));
themeData.palette.type = "light";
themeData.palette.text.primary = defaultTheme.palette.background.default;

const styles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loginComponent: {
    marginRight: theme.spacing(6),
  },
  title: {
    flexGrow: 1,
  },
  menuDrawer: {
    minWidth: 400,
    maxWidth: "30vw",
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(2),
    width: "100vw",
    zIndex: 0,
    textAlign: "center",
    color: "#90979E",
    textShadow:
      "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)",
  },
  footerVertical: {
    padding: theme.spacing(2),
    width: "100vw",
    zIndex: 0,
    textAlign: "center",
    color: "#90979E",
    textShadow:
      "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)",
  },
  footerLink: {
    color: "#90979E",
  },
  tutorialImage: {
    width: "100%",
    boxShadow: "0px 0px 15px -2px rgba(0,0,0,0.6)",
    borderRadius: 5,
  },
  profilesPopover: {
    padding: theme.spacing(2),
  },
  spellListDrawer: {
    maxWidth: 400,
  },
});

export default function App(props) {
  const { classes } = props;
  let tourManager = TourManager.getInstance();

  let loadingTexts = [
    "Casting fireball...",
    "Marking down spellslots...",
    "Spawning nalfeshnee...",
    "Praying to the gods...",
    "Searching for BBEG...",
    "Dispelling unseen servant...",
    "Searching for traps...",
    "Lighting torches...",
  ];

  const [state, setState] = useState({
    listDrawer: false,
    popoverVisible: false,
    popoverAnchor: null,
    popoverContent: "settings",
    tutorialVisible: true,
    loadingText:
      loadingTexts[Math.round(Math.random() * (loadingTexts.length - 1))],
    changelogSnackBarVisible: false,
    copiedSnackbarVisible: false,
    menuOpened: false,
  });

  let { deviceType, width, height } = useDeviceType();

  let isMobile = deviceType === "MOBILE";

  let anchorEl = {
    clientWidth: 10,
    clientHeight: 10,
    getBoundingClientRect: () => ({
      bottom: height / 2,
      top: height / 2,
      left: width / 2,
      right: width / 2,
    }),
  };

  let handleMenuClick = () => {
    tourManager.clickItem("menu");
    setState({
      ...state,
      menuOpened: true,
    });
  };

  let handleMenuClose = () => {
    setState({
      ...state,
      menuOpened: false,
    });
  };

  const menuButtonRef = useRef(undefined);

  const [tourState, setTourState] = useState({
    stepIndex: 0,
    steps: [
      {
        title: "Let's show you around!",
        content:
          "Click next to start the tour or the close button to try your luck!",
        placement: "center",
        target: "body",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Menu",
        content: "Click here to open the menu",
        spotlightPadding: 10,
        target: ".tour-menu-button",
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        disableBeacon: true,
        placement: "auto",
        spotlightClicks: true,
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        content: "Click here to go to your spellbook settings",
        spotlightPadding: 10,
        target: ".tour-spellbook-button",
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        disableBeacon: true,
        placement: "auto",
        spotlightClicks: true,
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "The basics",
        content:
          "Here you can change the name of your spellbook and select the classes of your character, your spell list will be populated with the spells of these classes.",
        placement: "auto",
        target: ".tour-spellbook-settings",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Additional spells",
        content:
          "Here you can add any extra spells that your character has access to which are not in the class spell list you've selected. A good example of these are patron spells!",
        placement: "auto",
        target: ".tour-spellbook-custom-spells",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Spell slots",
        content: "You can set how many spell slots you have by clicking here",
        placement: "auto",
        target: ".tour-spellslots-button",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Spells",
        content: "Click here to see your spell list",
        spotlightPadding: 10,
        target: ".tour-spells-button",
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        disableBeacon: true,
        placement: "auto",
        spotlightClicks: true,
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Viewing spells",
        content:
          "Hover or click on a spell name to see more information about it! Click the star to add the spell to your favorites, and click learn to add it to your spellbook!",
        placement: "auto",
        target: ".tour-spell-items",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Filtering spells",
        content: "You can filter spells by level by toggling these buttons",
        placement: "auto",
        target: ".tour-filter-level",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Filtering spells",
        content: "You can also search by name",
        placement: "auto",
        target: ".tour-filter-name",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Filtering spells",
        content:
          "And you can show only your favorited spells by toggling this button",
        placement: "auto",
        target: ".tour-filter-favorites",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Resetting spell slots",
        content:
          "You can click this button to reset all your spell slots. After taking a long rest for example!",
        placement: "auto",
        target: ".tour-reset-spells",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Custom spells",
        content:
          "You can make your own custom spells here, when you 'publish' your spell, it will show up for the classes you selected in the spell!",
        placement: "auto",
        target: ".tour-custom-spells",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "Other spellbooks",
        content:
          "You can have more than one spellbook, here you can switch between spellbooks!",
        placement: "auto",
        target: ".tour-spell-books",
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        title: "That's it!",
        content:
          "You are now ready to start tracking your spells! Go ahead and roll some dice!",
        placement: "center",
        target: "body",
        floaterProps: {
          disableAnimation: true,
        },
      },
    ],
  });

  // The tour manager is used to allow other components to tell the tour component that an item has been clicked
  tourManager.subscribe((event, clickedItem) => {
    if (event === "clicked") {
      if (clickedItem === "menu" && tourState.stepIndex === 1) {
        setTimeout(() => {
          setTourState({
            ...tourState,
            stepIndex: 2,
          });
        }, 400);
      }

      if (clickedItem === "spellbook-button" && tourState.stepIndex === 2) {
        setTimeout(() => {
          setTourState({
            ...tourState,
            stepIndex: 3,
          });
        }, 300);
      }

      if (clickedItem === "spells-button" && tourState.stepIndex === 6) {
        setTimeout(() => {
          setTourState({
            ...tourState,
            stepIndex: 7,
          });
          tourManager.requestClose("spell-details");
        }, 300);
      }
    }
  });

  let tourCallback = (data) => {
    // If the user clicks the "next" button, we should go to the next step
    if (data.action === "next" && data.type === "step:after") {
      if (tourState.stepIndex === 4) {
        tourManager.requestClose("spellbook-settings");

        setTimeout(() => {
          setTourState({
            ...tourState,
            stepIndex: tourState.stepIndex + 1,
          });
        }, 300);
      } else if (tourState.stepIndex === 10) {
        tourManager.requestClose("spells");

        setTimeout(() => {
          setTourState({
            ...tourState,
            stepIndex: tourState.stepIndex + 1,
          });
        }, 300);
      } else {
        setTourState({
          ...tourState,
          stepIndex: tourState.stepIndex + 1,
        });
      }
    }

    if (data.action === "next" && data.type === "tour:end") {
      setTourState({
        ...tourState,
        stepIndex: 0,
      });
      props.setShowGuidedTour(false);
    }

    if (data.action === "close") {
      setTourState({
        ...tourState,
        stepIndex: 0,
      });
      props.setShowGuidedTour(false);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={`${classes.menuButton}`}
            aria-label="home-icon"
          >
            <FontAwesomeIcon icon={faFire} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Pathfinder spellbook
          </Typography>
          {!isMobile ? (
            <GoogleLoginComponent className={classes.loginComponent} />
          ) : undefined}

          <IconButton
            ref={menuButtonRef}
            edge="start"
            className={`${classes.menuButton} tour-menu-button`}
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Overview />
      <MobileMenu
        handleMenuClose={handleMenuClose}
        menuOpened={state.menuOpened}
      />

      {props.spellList.length < 1 ? (
        <div
          style={{
            zIndex: 1450,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        ></div>
      ) : (
        ""
      )}
      <Popper
        style={{ zIndex: 1500 }}
        open={props.spellList.length < 1}
        anchorEl={anchorEl}
        transition
        placement="top"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper
              style={{ height: 200, width: 300 }}
              className={classes.popper}
            >
              <Grid
                direction="column"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                spacing={1}
                container
              >
                <Grid style={{ padding: "32px" }} item>
                  <CircularProgress size={60} color="primary" />
                </Grid>
                <Grid item>
                  <Typography
                    style={{ padding: "12px" }}
                    align="center"
                    variant="h6"
                    color="primary"
                  >
                    {state.loadingText}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Changelog />
      <SpellbookGuide />

      <div
        className={
          props.showVerticalSpellList || deviceType === "MOBILE"
            ? classes.footerVertical
            : classes.footer
        }
      >
        <div>{isLoggedIn() ? <SyncIndicator /> : undefined}</div>
        <div>
          Copyright &#169; {new Date().getFullYear()} Milan van
          Dijck&nbsp;|&nbsp;
          <a
            className={classes.footerLink}
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.tabletop.cloud"
          >
            Tabletop Cloud
          </a>
          &nbsp;|&nbsp;
          <a
            className={classes.footerLink}
            rel="noopener noreferrer"
            target="_blank"
            href="https://gitlab.com/tabletop-cloud/spellbook/-/issues"
          >
            GitLab issue tracker
          </a>
          &nbsp;|&nbsp;
          <a
            className={classes.footerLink}
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.tabletop.cloud/spellbook-changelog"
          >
            Changelog
          </a>
          &nbsp;|&nbsp;
          <a
            className={classes.footerLink}
            href="/licensing.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            Pathfinder Compatibility
          </a>
        </div>
      </div>
      <ReactJoyride
        continuous
        run={props.showGuidedTour ? true : false}
        stepIndex={tourState.stepIndex}
        callback={tourCallback}
        scrollToFirstStep
        hideBackButton
        steps={tourState.steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: theme.palette.primary.main,
          },
        }}
      ></ReactJoyride>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  retrieveSpells,
  resetSpellSlots,
  retrieveCategorizedSpells,
  setTutorialViewed,
  setShowGuidedTour: showGuidedTour,
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
  showVerticalSpellList: state.interfaceData.showVerticalSpellList,
  showGuidedTour: state.interfaceData.showGuidedTour,
});

const Application = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(App);

export { Application };
