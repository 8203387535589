import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography, createStyles, withStyles } from "@material-ui/core";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

let styles = () =>
  createStyles({
    root: {
      maxWidth: 75,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.54);",
    },
  });

interface TextIconButtonProps {
  classes: ClassNameMap;
  className?: string;
  icon: IconProp;
  title: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

function TextIconButton(props: TextIconButtonProps) {
  let { classes } = props;
  return (
    <div
      onClick={props.onClick}
      className={`${classes.root} ${props.className}`}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={1}
        className={classes.root}
      >
        <Grid item>
          <Typography>
            <FontAwesomeIcon size="2x" icon={props.icon} />
          </Typography>
        </Grid>
        <Grid item>{props.title}</Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(TextIconButton);
