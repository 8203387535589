import { Divider, Grid, TextField, Theme, withStyles } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { compose } from "redux";
import { connect } from "react-redux";
import React from "react";
import { State } from "../../reducers";
import {
  setSpellSlots,
  setSpellbookName,
  addClass,
  removeClass,
  addCustomSpell,
  removeCustomSpell,
} from "../../actions/spellbookActions";
import {
  setClassesFilter,
  showVerticalSpellListChange,
} from "../../actions/interfaceActions";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { SpellbookProfileData } from "../../reducers/spellbookData";
import { ClassOrderedSpellList } from "../../reducers/interfaceData";
import CustomSpellSettings from "./CustomSpellSettings";
import { combineSpellListCategories } from "../../tools/UserSpellTools";

const styles = (theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  spellbookName: {
    width: "100%",
  },
  helpText: {
    color: "#888",
  },
  classSelector: {
    width: "100%",
  },
  addButton: {
    width: 100,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

export interface SpellbookSettingsProps {
  classes: ClassNameMap;
  profile: SpellbookProfileData;
  setSpellbookName: (name: string) => void;
  setClassesFilter: (classes: string[]) => void;
  addClass: (className: string) => void;
  removeClass: (className: string) => void;
  spellListCategories: ClassOrderedSpellList;
}

const arrayNonIntersect = (array1: any[], array2: any[]) => {
  return array1.filter((value) => {
    return !array2.includes(value);
  });
};

function SpellbookSettings(props: SpellbookSettingsProps) {
  let { classes, profile } = props;

  let pathfinderClasses = Object.keys(props.spellListCategories);
  pathfinderClasses = pathfinderClasses.sort();

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSpellbookName(event.target.value);
  };

  const onClassChange = (
    event: React.ChangeEvent<{}>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    props.setClassesFilter(value);

    let addedItems = arrayNonIntersect(value, props.profile.classes);
    let removedItems = arrayNonIntersect(props.profile.classes, value);

    if (addedItems.length > 0) {
      props.addClass(addedItems[0]);
    }

    if (removedItems.length > 0) {
      props.removeClass(removedItems[0]);
    }
  };

  return (
    <React.Fragment>
      <Grid
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
        container
        className={`${classes.fullWidth} tour-spellbook-settings`}
      >
        <Grid className={classes.fullWidth} item>
          <TextField
            label="Spellbook name"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onNameChange}
            variant="outlined"
            color="primary"
            value={profile.name}
            className={classes.spellbookName}
          ></TextField>
        </Grid>
        <Grid className={classes.fullWidth} item>
          <div className={classes.classSelector}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={pathfinderClasses}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              onChange={onClassChange}
              defaultValue={profile.classes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Classes"
                  helperText="The classes you select decide which spell lists you have access to"
                  placeholder="Start typing..."
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <CustomSpellSettings />
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  setSpellSlots,
  setSpellbookName,
  setClassesFilter,
  addClass,
  removeClass,
  addCustomSpell,
  removeCustomSpell,
  showVerticalSpellListChange,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellListCategories: combineSpellListCategories(
    state.interfaceData.spellListCategories,
    state.spellbookData.userSpells
  ),
});

export default compose<React.FunctionComponent>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellbookSettings);
