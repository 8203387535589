import { Grid, Slider, Switch, Theme, Typography } from "@material-ui/core";
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { State } from "../../reducers";
import {
  showVerticalSpellListChange,
  setSpellCardWidth,
} from "../../actions/interfaceActions";

const styles = (theme: Theme) => ({
  root: {
    width: 650,
  },
  firstTab: {
    width: 650,
  },
  spellbookName: {
    width: 300,
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  helpText: {
    color: "#888",
  },
  classSelector: {
    width: 300,
  },
  addButton: {
    width: 100,
  },
});

interface InterfaceSettingsProps {
  classes: ClassNameMap;
  showVerticalSpellList?: boolean;
  spellCardWidth?: number;
  showVerticalSpellListChange: (showVerticalSpellList: boolean) => void;
  setSpellCardWidth: (width: number) => void;
}

function InterfaceSettings(props: InterfaceSettingsProps) {
  let { classes } = props;

  const onVerticalSpellListSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.showVerticalSpellListChange(checked);
  };

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
      container
    >
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
        item
      >
        <Grid item>
          <Switch
            checked={props.showVerticalSpellList ? true : false}
            onChange={onVerticalSpellListSwitch}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.helpText}>
            Show spell list vertically
          </Typography>
        </Grid>
      </Grid>
      <Grid direction="column" item container>
        <Grid item>
          <Typography className={classes.helpText}>
            Spell collumn width
          </Typography>
        </Grid>
        <Grid item>
          <Slider
            aria-label="Pixels"
            getAriaValueText={(value) => `${value}px`}
            value={props.spellCardWidth ? props.spellCardWidth : 300}
            onChange={(event, value) => {
              props.setSpellCardWidth(value as number);
            }}
            step={25}
            marks
            min={300}
            max={1000}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  showVerticalSpellListChange,
  setSpellCardWidth,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  showVerticalSpellList: state.interfaceData.showVerticalSpellList,
  spellCardWidth: state.interfaceData.spellCardWidth,
});

export default compose<React.FunctionComponent>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(InterfaceSettings);
