import {
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { SpellLevels } from "../containers/CustomSpells/CustomSpellInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CLASS_OPTIONS = [
  "Sorcerer",
  "Wizard",
  "Cleric",
  "Druid",
  "Ranger",
  "Bard",
  "Paladin",
  "Alchemist",
  "Summoner",
  "Witch",
  "Inquisitor",
  "Oracle",
  "Antipaladin",
  "Magus",
  "Adept",
  "Deity",
  "Bloodrager",
  "Shaman",
  "Psychic",
  "Medium",
  "Mesmerist",
  "Occultist",
  "Spiritualist",
  "Skald",
  "Investigator",
  "Hunter",
  "Summoner (Unchained)",
  "Arcanist",
  "Warpriest",
];

interface SpellLevelSelectorProps {
  spellLevels: SpellLevels;
  onChange: (spellLevels: SpellLevels) => void;
}

export default function SpellLevelSelector({
  spellLevels,
  onChange,
}: SpellLevelSelectorProps) {
  let onSpellLevelChange = (className: string, spellLevel: number) => {
    let newSpellLevels = { ...spellLevels };
    newSpellLevels[className] = spellLevel;
    onChange(newSpellLevels);
  };

  let onSpellLevelRemove = (className: string) => {
    let newSpellLevels = { ...spellLevels };
    delete newSpellLevels[className];
    onChange(newSpellLevels);
  };

  let renderSpellLevelEditor = (className: string, spellLevel: number) => {
    return (
      <Grid
        key={className}
        direction="row"
        alignContent="space-between"
        alignItems="center"
        spacing={2}
        item
        container
      >
        <Grid xs={5} item>
          {className}
        </Grid>
        <Grid xs={5} item>
          <FormControl
            style={{ width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel>Level</InputLabel>
            <Select
              value={spellLevel}
              onChange={(event) => {
                onSpellLevelChange(className, event.target.value as number);
              }}
              label="Level"
            >
              <MenuItem value={0}>Cantrip</MenuItem>
              <MenuItem value={1}>1st</MenuItem>
              <MenuItem value={2}>2nd</MenuItem>
              <MenuItem value={3}>3rd</MenuItem>
              <MenuItem value={4}>4th</MenuItem>
              <MenuItem value={5}>5th</MenuItem>
              <MenuItem value={6}>6th</MenuItem>
              <MenuItem value={7}>7th</MenuItem>
              <MenuItem value={8}>8th</MenuItem>
              <MenuItem value={9}>9th</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FontAwesomeIcon
            onClick={(event) => {
              event.stopPropagation();
              onSpellLevelRemove(className);
            }}
            size="2xl"
            icon="times"
          />
        </Grid>
      </Grid>
    );
  };

  let spellLevelEditors: Array<React.JSX.Element> = [];
  for (const [className, spellLevel] of Object.entries(spellLevels)) {
    if (typeof spellLevel === "undefined") continue;
    spellLevelEditors.push(renderSpellLevelEditor(className, spellLevel));
  }

  let selectableClasses = CLASS_OPTIONS.filter((className) => {
    return typeof spellLevels[className] === "undefined";
  });

  const [selectedClass, setSelectedClass] = useState(selectableClasses[0]);

  return (
    <Grid style={{ marginTop: 4 }} container direction="column" spacing={2}>
      {spellLevelEditors}
      <Grid
        spacing={2}
        alignItems="center"
        alignContent="space-between"
        container
        item
      >
        <Grid xs item>
          <FormControl
            style={{ width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel>Class</InputLabel>
            <Select
              value={selectedClass}
              onChange={(event) => {
                setSelectedClass(event.target.value as string);
              }}
              label="Class"
            >
              {selectableClasses.map((className) => (
                <MenuItem key={`sl-${className}`} value={className}>
                  {className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSpellLevelChange(selectedClass, 0);
              if (selectableClasses[0] === selectedClass) {
                setSelectedClass(selectableClasses[1]);
              } else {
                setSelectedClass(selectableClasses[0]);
              }
            }}
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;&nbsp;Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
