import React, { useState } from "react";
import { Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";
import { theme as defaultTheme } from "../themes/theme";
import SpellInfo from "../components/SpellInfo";
import spellSchoolItems from "../themes/SpellSchoolIcons";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import CustomCardHeader from "../components/CustomCardHeader";
import useDeviceType, { DeviceType } from "../tools/DeviceInfo";
import { State } from "../reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface SpellCardProps {
  spellName: string;
  spellSchool: string;
  spellLevel: number;
  spellId: string;
  showVertical?: boolean;
  preparedSpells?: number;
  preparedSpellsAdjuster: (value: number) => void;
  onCastClick: () => void;
  classes: ClassNameMap;
}

const themeData = JSON.parse(JSON.stringify(defaultTheme));
themeData.palette.type = "light";
themeData.palette.text.primary = defaultTheme.palette!.background!.default;

const muiLightTheme = createTheme(themeData);

const styles = (theme: Theme) => ({
  spellCard: {
    cursor: "pointer",
    width: "100%",
  },
  cardHeader: {
    //height: 52
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  classButton: {
    height: "100%",
  },
  prepareContainer: {
    paddingTop: theme.spacing(1),
  },
});

const SpellCard: React.FC<SpellCardProps> = (props) => {
  const classes = props.classes;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let iconData = spellSchoolItems[props.spellSchool];
  if (typeof iconData === "undefined") {
    iconData = spellSchoolItems["Evocation"];
  }

  let addPreparation = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    props.preparedSpellsAdjuster(1);
  };

  let removePreparation = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    props.preparedSpellsAdjuster(-1);
  };

  let isPreparedSpell = typeof props.preparedSpells !== "undefined";
  let preparedSpells = props.preparedSpells;

  let { deviceType } = useDeviceType();

  let showVertical = props.showVertical || deviceType === "MOBILE";

  let preparedBlock = (
    <Grid
      className={classes.prepareContainer}
      spacing={1}
      alignItems="center"
      justifyContent="center"
      container
    >
      <Grid item>Prepared:</Grid>
      <Grid item>
        <FontAwesomeIcon
          onClick={removePreparation}
          icon={faMinusCircle}
          color={themeData.palette.primary.main}
          size="2x"
        />
      </Grid>
      <Grid item>{preparedSpells}</Grid>
      <Grid item>
        <FontAwesomeIcon
          onClick={addPreparation}
          icon={faPlusCircle}
          color={themeData.palette.primary.main}
          size="2x"
        />
      </Grid>
    </Grid>
  );

  let spellCardStyle: React.CSSProperties = {};
  if (deviceType !== DeviceType.MOBILE && !showVertical) {
    spellCardStyle["width"] = "100%";
  }

  return (
    <div onClick={handleExpandClick}>
      <CssBaseline></CssBaseline>
      <ThemeProvider theme={muiLightTheme}>
        <Card className={classes.spellCard} style={spellCardStyle}>
          <CustomCardHeader
            title={props.spellName}
            subTitle={props.spellSchool}
            icon={
              <FontAwesomeIcon
                icon={iconData.icon}
                color={iconData.color}
                size="lg"
              ></FontAwesomeIcon>
            }
            action={
              props.spellLevel !== 0 ? (
                <Grid alignItems="center" justifyContent="center" container>
                  <Grid item>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        props.onCastClick();
                      }}
                      variant="outlined"
                      color="primary"
                      disabled={isPreparedSpell && preparedSpells === 0}
                    >
                      Cast
                      {isPreparedSpell ? (
                        <span>&nbsp;({preparedSpells})</span>
                      ) : undefined}
                    </Button>
                  </Grid>
                </Grid>
              ) : undefined
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {isPreparedSpell ? (
              <CardContent className={classes.prepareContainer}>
                {preparedBlock}
              </CardContent>
            ) : (
              <span></span>
            )}
            <CardContent>
              <SpellInfo spellId={props.spellId} />
            </CardContent>
          </Collapse>
        </Card>
      </ThemeProvider>
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
});

export default compose<React.FC<Partial<SpellCardProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellCard);
