import * as Sentry from "@sentry/browser";
import { getSpellInfo } from "../tools/ApiManager";
import {
  getSpells,
  getSpellsChanged,
  getCategorizedSpells,
} from "../tools/ApiManager";

export const actions = {
  CHANGE_SEARCH_TERM: "CHANGE_SEARCH_TERM",
  CHANGE_SPELL_LEVEL_FILTER: "CHANGE_SPELL_LEVEL_FILTER",
  SPELLS_DOWNLOAD_COMPLETE: "SPELLS_DOWNLOAD_COMPLETE",
  SPELLS_CATEGORY_DOWNLOAD_COMPLETE: "SPELLS_CATEGORY_DOWNLOAD_COMPLETE",
  SPELLS_DOWNLOAD_PENDING: "SPELLS_DOWNLOAD_PENDING",
  SPELLS_CATEGORY_DOWNLOAD_PENDING: "SPELLS_CATEGORY_DOWNLOAD_PENDING",
  SPELL_CARD_POPOVER_SET_STATE: "SPELL_CARD_POPOVER_SET_STATE",
  SET_CLASSES_FILTER: "SET_CLASSES_FILTER",
  SPELL_DOWNLOAD_FINISHED: "SPELL_DOWNLOAD_FINISHED",
  SET_SPELL_IS_DOWNLOADING: "SET_SPELL_IS_DOWNLOADING",
  SET_TUTORIAL_VIEWED: "SET_TUTORIAL_VIEWED",
  SET_CLOUD_SYNC_COUNTDOWN: "SET_CLOUD_SYNC_COUNTDOWN",
  VERTICAL_SPELL_LIST_CHANGE: "VERTICAL_SPELL_LIST_CHANGE",
  SET_SHOW_RECENT_UPDATE: "SET_SHOW_RECENT_UPDATE",
  SHOW_SPELLBOOK_GUIDE: "SHOW_SPELLBOOK_GUIDE",
  SET_SPELL_CARD_WIDTH: "SET_SPELL_CARD_WIDTH",
  SHOW_FAVORITES_ONLY: "SHOW_FAVORITES_ONLY",
  SHOW_GUIDED_TOUR: "SHOW_GUIDED_TOUR",
  UPDATE_CHANGELOG: "UPDATE_CHANGELOG",
  SET_CHANGELOG_VIEWED: "SET_CHANGELOG_VIEWED",
};

export const setTutorialViewed = (value = true) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_TUTORIAL_VIEWED,
      value: !value,
    });
  };
};

export const downloadSpell = (spellId) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SPELL_IS_DOWNLOADING,
      spellId: spellId,
    });

    // TODO - Add error handling here to showing download pending
    getSpellInfo(spellId)
      .then((data) => {
        dispatch({
          type: actions.SPELL_DOWNLOAD_FINISHED,
          spellId: spellId,
          spell: data,
          failed: false,
        });
      })
      .catch((e) => {
        dispatch({
          type: actions.SPELL_DOWNLOAD_FINISHED,
          failed: true,
        });
      });
  };
};

export const setSearchTerm = (searchTerm) => {
  return (dispatch) => {
    dispatch({
      type: actions.CHANGE_SEARCH_TERM,
      searchTerm: searchTerm,
    });
  };
};

export const setSpellLevelFilter = (spellLevel, value) => {
  return (dispatch) => {
    dispatch({
      type: actions.CHANGE_SPELL_LEVEL_FILTER,
      spellLevel: spellLevel,
      value: value,
    });
  };
};

export const retrieveSpells = (isEmpty, lastUpdate) => {
  return async (dispatch) => {
    try {
      let apiResponse = await getSpellsChanged();

      if (lastUpdate < apiResponse.lastUpdate || isEmpty) {
        dispatch({
          type: actions.SPELLS_DOWNLOAD_PENDING,
        });

        let apiSpells = await getSpells();

        dispatch({
          type: actions.SPELLS_DOWNLOAD_COMPLETE,
          spells: apiSpells,
        });
      } else {
        console.log("We already have the most recent list of spells!");
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };
};

export const retrieveCategorizedSpells = (isEmpty, lastUpdate) => {
  return async (dispatch) => {
    try {
      let apiResponse = await getSpellsChanged();

      if (lastUpdate < apiResponse.lastUpdate || isEmpty) {
        dispatch({
          type: actions.SPELLS_CATEGORY_DOWNLOAD_PENDING,
        });

        let categorizedSpells = await getCategorizedSpells();

        dispatch({
          type: actions.SPELLS_CATEGORY_DOWNLOAD_COMPLETE,
          list: categorizedSpells,
        });

        // TODO - Add error handling here to showing download pending
      } else {
        console.log(
          "We already have the most recent list of categorized spells!"
        );
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };
};

export const setSpellCardPopoverState = (isOpen, spell) => {
  return (dispatch) => {
    dispatch({
      type: actions.SPELL_CARD_POPOVER_SET_STATE,
      isOpen: isOpen,
      spell: spell,
    });
  };
};

export const setClassesFilter = (classes) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_CLASSES_FILTER,
      classes: classes,
    });
  };
};

export const showVerticalSpellListChange = (show) => {
  return (dispatch) => {
    dispatch({
      type: actions.VERTICAL_SPELL_LIST_CHANGE,
      show: show,
    });
  };
};

export const setShowRecentUpdate = (show) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SHOW_RECENT_UPDATE,
      show: show,
    });
  };
};

export const showSpellbookGuide = (show) => {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_SPELLBOOK_GUIDE,
      show: show,
    });
  };
};

export const setSpellCardWidth = (width) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SPELL_CARD_WIDTH,
      width: width,
    });
  };
};

export const setFavoritesOnly = (favoriteOnly) => {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_FAVORITES_ONLY,
      favoritesOnly: favoriteOnly,
    });
  };
};

export const showGuidedTour = (show) => {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_GUIDED_TOUR,
      show: show,
    });
  };
};

export const updateChangelog = (changelog) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_CHANGELOG,
      changelog: changelog,
    });
  };
};

export const setChangelogViewed = (version) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_CHANGELOG_VIEWED,
      version: version,
    });
  };
};
