/*import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";

const useDeviceInfo = () => {
  const [state, setState] = useState({});

  setState({
    isDesktopOrLaptop: useMediaQuery({ minDeviceWidth: 1224 }),
    isBigScreen: useMediaQuery({ minDeviceWidth: 1824 }),
    isMobile: useMediaQuery({ maxWidth: 1224 }),
    isTablet:
      useMediaQuery({ minWidth: 768 }) && useMediaQuery({ maxWidth: 1024 }),
    isMobileDevice: useMediaQuery({ maxDeviceWidth: 1224 }),
    isPortrait: useMediaQuery({ orientation: "portrait" }),
    isRetina: useMediaQuery({ minResolution: "2dppx" }),
  });

  return state;
};

export default useDeviceInfo;
*/

import { useEffect, useState } from "react";

export enum DeviceType {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
  TABLET = "TABLET",
}

const useDeviceType: () => {
  deviceType: DeviceType;
  width: number;
  height: number;
} = () => {
  const [state, setState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let deviceType: DeviceType;
  if (state.width <= 1224 && state.width >= 768) {
    deviceType = DeviceType.TABLET;
  } else if (state.width < 768) {
    deviceType = DeviceType.MOBILE;
  } else {
    deviceType = DeviceType.DESKTOP;
  }

  return {
    deviceType: deviceType,
    width: state.width,
    height: state.height,
  };
};

export default useDeviceType;
