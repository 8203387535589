import { v4 as uuidv4 } from "uuid";
import { actions } from "../actions/spellbookActions";
import * as Sentry from "@sentry/browser";

let mainProfileId = uuidv4();

const spellbookData = (
  state = {
    selectedProfileId: mainProfileId,
    version: 2,
    profiles: {
      [mainProfileId]: {
        profileVersion: 2,
        version: 0,
        type: "local",
        current: {
          name: "Spellbook 1",
          classes: ["Wizard"],
          favoriteSpells: {},
          customSpells: {
            "lvl-0": [],
            "lvl-1": [],
            "lvl-2": [],
            "lvl-3": [],
            "lvl-4": [],
            "lvl-5": [],
            "lvl-6": [],
            "lvl-7": [],
            "lvl-8": [],
            "lvl-9": [],
          },
          learnedSpells: {
            cantrip: [],
            first: [],
            second: [],
            third: [],
            fourth: [],
            fifth: [],
            sixth: [],
            seventh: [],
            eighth: [],
            ninth: [],
          },
          spellSlots: [
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
            { slots: 0, used: 0 },
          ],
        },
        old: {},
      },
    },
    spellDrafts: {},
  },
  action
) => {
  switch (action.type) {
    case actions.SET_SPELL_SLOTS:
      return changeSpellSlot(state, action);

    case actions.SET_SPELLBOOK_NAME:
      return changeName(state, action);

    case actions.ADD_CLASS:
      return addClass(state, action);

    case actions.REMOVE_CLASS:
      return removeClass(state, action);

    case actions.LEARN_SPELL:
      return learnSpell(state, action);

    case actions.UNLEARN_SPELL:
      return unlearnSpell(state, action);

    case actions.USE_SPELL_SLOT:
      return setSpellSlotUsed(state, action);

    case actions.FREE_SPELL_SLOT:
      return setSpellSlotFree(state, action);

    case actions.RESET_SPELL_SLOTS:
      return resetSpellSlots(state, action);

    case actions.TOGGLE_FAVORITE_SPELL:
      return toggleFavoriteSpell(state, action);

    case actions.SELECT_SPELLBOOK:
      return selectSpellbook(state, action);

    case actions.ADD_SPELLBOOK:
      return addSpellbook(state, action);

    case actions.ADJUST_SPELL_PREPARATIONS:
      return adjustSpellPreparations(state, action);

    case actions.ADD_CUSTOM_SPELL:
      return addCustomSpell(state, action);

    case actions.REMOVE_CUSTOM_SPELL:
      return removeCustomSpell(state, action);

    case actions.PROFILE_TO_LOCAL:
      return profileToLocal(state, action);

    case actions.PROFILE_TO_CLOUD:
      return profileToCloud(state, action);

    case actions.CLOUD_SYNC:
      return cloudSync(state, action);

    case actions.SPELL_CLOUD_SYNC:
      return spellCloudSync(state, action);

    case actions.UPDATE_USER_SPELL:
      return updateUserSpell(state, action);

    case actions.DELETE_USER_SPELL:
      return deleteUserSpell(state, action);

    default:
      return state;
  }
};

export { spellbookData };

/**
 * Reducer functions
 */
function addSpellbook(state, action) {
  let profileId = uuidv4();

  let newState = {
    ...state,
    selectedProfileId: profileId,
    profiles: {
      ...state.profiles,
      [profileId]: {
        profileVersion: 2,
        version: 0,
        type: "local", // TODO this should be cloud if the user is loggedin to a cloud account
        current: {
          ...action.spellbook,
        },
        old: {},
      },
    },
  };

  return newState;
}

function selectSpellbook(state, action) {
  let newState = {
    ...state,
    selectedProfileId: action.spellbookId,
  };

  Sentry.setContext("SpellbookProfile", { profileId: action.spellbookId });

  return newState;
}

function toggleFavoriteSpell(state, action) {
  let classSpells = {};

  if (
    typeof state.profiles[state.selectedProfileId].current.favoriteSpells[
      action.spellClass
    ] !== "undefined"
  ) {
    let newLvlSpells = [
      ...state.profiles[state.selectedProfileId].current.favoriteSpells[
        action.spellClass
      ]["lvl-" + action.spellLevel],
    ];

    if (action.spellClass === "Custom") {
      // If the current array includes the favorite item remove it, otherwise add it.
      let index = newLvlSpells.findIndex((element) => {
        return (
          element.isPrepared === action.spellId.isPrepared &&
          element.spellId === action.spellId.spellId
        );
      });

      if (index > -1) {
        newLvlSpells = newLvlSpells.slice();
        newLvlSpells.splice(index, 1);
      } else {
        newLvlSpells.push(action.spellId);
      }
    } else {
      // If the current array includes the favorite item remove it, otherwise add it.
      if (newLvlSpells.includes(action.spellId)) {
        newLvlSpells = newLvlSpells.filter((element) => {
          return parseInt(element) !== parseInt(action.spellId);
        });
      } else {
        newLvlSpells.push(action.spellId);
      }
    }

    classSpells = {
      ...state.profiles[state.selectedProfileId].current.favoriteSpells[
        action.spellClass
      ],
      ["lvl-" + action.spellLevel]: newLvlSpells,
    };
  } else {
    classSpells = {
      "lvl-0": [],
      "lvl-1": [],
      "lvl-2": [],
      "lvl-3": [],
      "lvl-4": [],
      "lvl-5": [],
      "lvl-6": [],
      "lvl-7": [],
      "lvl-8": [],
      "lvl-9": [],
    };

    classSpells["lvl-" + action.spellLevel] = [action.spellId];
  }

  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          favoriteSpells: {
            ...state.profiles[state.selectedProfileId].current.favoriteSpells,
            [action.spellClass]: classSpells,
          },
        },
      },
    },
  };

  return newState;
}

function changeSpellSlot(state, action) {
  let newSpellSlotArray =
    state.profiles[state.selectedProfileId].current.spellSlots.slice();

  newSpellSlotArray = newSpellSlotArray.map((item, index) => {
    if (index !== action.spellslot - 1) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    let newItem = {
      ...item,
      slots: parseInt(action.count),
    };

    return newItem;
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          spellSlots: newSpellSlotArray,
        },
      },
    },
  };

  return newState;
}

function changeName(state, action) {
  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          name: action.name,
        },
      },
    },
  };
  return newState;
}

function addClass(state, action) {
  var newClasses = addItem(
    state.profiles[state.selectedProfileId].current.classes,
    action.className
  );

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          classes: newClasses,
        },
      },
    },
  };
  return newState;
}

function removeClass(state, action) {
  var newClasses = removeItem(
    state.profiles[state.selectedProfileId].current.classes,
    action.className
  );

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          classes: newClasses,
        },
      },
    },
  };
  return newState;
}

function learnSpell(state, action) {
  let spellLevelLabels = [
    "cantrip",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
  ];

  let currentLearnedSpells =
    state.profiles[state.selectedProfileId].current.learnedSpells[
      spellLevelLabels[action.level]
    ];
  if (typeof currentLearnedSpells === "undefined") {
    currentLearnedSpells = [];
  }

  let learnedSpell = {};
  if (typeof action.spellId === "object") {
    learnedSpell = {
      spellId: action.spellId.spellId,
      className: action.className,
      level: action.level,
      isPrepared: action.spellId.isPrepared,
    };
  } else {
    learnedSpell = {
      spellId: action.spellId,
      className: action.className,
      level: action.level,
      isPrepared: false,
    };
  }

  let newLearnedSpells = addItem(currentLearnedSpells, learnedSpell);

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          learnedSpells: {
            ...state.profiles[state.selectedProfileId].current.learnedSpells,
            [spellLevelLabels[action.level]]: newLearnedSpells,
          },
        },
      },
    },
  };

  return newState;
}

function adjustSpellPreparations(state, action) {
  let spellLevelLabels = [
    "cantrip",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
  ];

  let spellsAtLevel =
    state.profiles[state.selectedProfileId].current.learnedSpells[
      spellLevelLabels[action.level]
    ];

  let newLearnedSpells = spellsAtLevel.map((element) => {
    if (
      element.spellId === action.spellId &&
      element.className === action.className
    ) {
      let preparedSpells;

      if (typeof element.preparedSpells !== "undefined") {
        preparedSpells = element.preparedSpells + action.adjustment;

        if (preparedSpells < 0) preparedSpells = 0; // No you cannot prepare negative spells....
      } else {
        if (action.adjustment > 0) {
          preparedSpells = action.adjustment;
        } else {
          preparedSpells = 0;
        }
      }

      let newElement = {
        ...element,
        preparedSpells: preparedSpells,
      };

      return newElement;
    }

    return element;
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          learnedSpells: {
            ...state.profiles[state.selectedProfileId].current.learnedSpells,
            [spellLevelLabels[action.level]]: newLearnedSpells,
          },
        },
      },
    },
  };

  return newState;
}

function unlearnSpell(state, action) {
  let spellLevelLabels = [
    "cantrip",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
  ];

  let newLearnedSpells =
    state.profiles[state.selectedProfileId].current.learnedSpells[
      spellLevelLabels[action.level]
    ].slice();

  newLearnedSpells = newLearnedSpells.filter((element) => {
    if (typeof action.spellId === "object") {
      return !(
        element.spellId === action.spellId.spellId &&
        element.className === action.className &&
        element.isPrepared === action.spellId.isPrepared
      );
    } else {
      return !(
        element.spellId === action.spellId &&
        element.className === action.className
      );
    }
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          learnedSpells: {
            ...state.profiles[state.selectedProfileId].current.learnedSpells,
            [spellLevelLabels[action.level]]: newLearnedSpells,
          },
        },
      },
    },
  };

  return newState;
}

function resetSpellSlots(state, action) {
  let newSpellSlotArray = state.profiles[
    state.selectedProfileId
  ].current.spellSlots.map((item) => {
    return {
      ...item,
      used: 0,
    };
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          spellSlots: newSpellSlotArray,
        },
      },
    },
  };

  return newState;
}

function setSpellSlotUsed(state, action) {
  let newSpellSlotArray =
    state.profiles[state.selectedProfileId].current.spellSlots.slice();

  newSpellSlotArray = newSpellSlotArray.map((item, index) => {
    if (index !== action.level - 1) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    if (item.used === item.slots) {
      // All spellslots have been used
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    let newItem = {
      ...item,
      used: item.used + 1,
    };

    return newItem;
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          spellSlots: newSpellSlotArray,
        },
      },
    },
  };

  return newState;
}

function setSpellSlotFree(state, action) {
  let newSpellSlotArray =
    state.profiles[state.selectedProfileId].current.spellSlots.slice();

  newSpellSlotArray = newSpellSlotArray.map((item, index) => {
    if (index !== action.level - 1) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    if (item.used === 0) {
      // We are already at 0
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    let newItem = {
      ...item,
      used: item.used - 1,
    };

    return newItem;
  });

  var newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          spellSlots: newSpellSlotArray,
        },
      },
    },
  };

  return newState;
}

function addCustomSpell(state, action) {
  let arrayKey = "lvl-" + action.spellLevel;

  let existingCustomSpells;
  let newArray = [];

  if (
    typeof state.profiles[state.selectedProfileId].current.customSpells ===
      "undefined" ||
    Array.isArray(state.profiles[state.selectedProfileId].current.customSpells)
  ) {
    newArray.push({
      spellId: action.spellId,
      isPrepared: action.isPrepared,
    });
    existingCustomSpells = {
      "lvl-0": [],
      "lvl-1": [],
      "lvl-2": [],
      "lvl-3": [],
      "lvl-4": [],
      "lvl-5": [],
      "lvl-6": [],
      "lvl-7": [],
      "lvl-8": [],
      "lvl-9": [],
    };
  } else {
    newArray = [
      ...state.profiles[state.selectedProfileId].current.customSpells[arrayKey],
    ];
    let newCustomSpell = {
      spellId: action.spellId,
      isPrepared: action.isPrepared,
    };

    let filteredArray = newArray.filter((item) => {
      if (typeof item === "object") {
        return (
          item.spellId === action.spellId &&
          item.isPrepared === action.isPrepared
        );
      } else {
        return false;
      }
    });

    if (filteredArray.length < 1) {
      newArray.push(newCustomSpell);
    } else {
      return state;
    }

    existingCustomSpells =
      state.profiles[state.selectedProfileId].current.customSpells;
  }

  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          customSpells: {
            ...existingCustomSpells,
            [arrayKey]: newArray,
          },
        },
      },
    },
  };

  return newState;
}

function removeCustomSpell(state, action) {
  let arrayKey = "lvl-" + action.spellLevel;
  let newArray = state.profiles[state.selectedProfileId].current.customSpells[
    arrayKey
  ].filter((spell) => {
    return !(
      parseInt(spell.spellId) === action.spellId &&
      spell.isPrepared === action.isPrepared
    );
  });

  let newLearnedSpells = {};
  let oldLearnedSpells =
    state.profiles[state.selectedProfileId].current.learnedSpells;

  let spellLevelLabels = [
    "cantrip",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
  ];

  for (let [level, spells] of Object.entries(oldLearnedSpells)) {
    let spellLevel = spellLevelLabels.indexOf(level);
    // if this is the level of the spell that is being deleted, search this array and remove the spell
    if (action.spellLevel === spellLevel) {
      newLearnedSpells[level] = spells.filter((item) => {
        return !(
          parseInt(item.spellId) === parseInt(action.spellId) &&
          item.className === "Custom" &&
          item.isPrepared === action.isPrepared
        );
      });
    } else {
      // otherwise, just add the contents to the new array
      newLearnedSpells[level] = spells;
    }
  }

  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [state.selectedProfileId]: {
        ...state.profiles[state.selectedProfileId],
        current: {
          ...state.profiles[state.selectedProfileId].current,
          customSpells: {
            ...state.profiles[state.selectedProfileId].current.customSpells,
            [arrayKey]: newArray,
          },
          learnedSpells: newLearnedSpells,
        },
      },
    },
  };

  return newState;
}

function profileToCloud(state, action) {
  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [action.profileId]: {
        ...state.profiles[action.profileId],
        type: "cloud",
      },
    },
  };

  return newState;
}

function profileToLocal(state, action) {
  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      [action.profileId]: {
        ...state.profiles[action.profileId],
        type: "local",
      },
    },
  };

  return newState;
}

function cloudSync(state, action) {
  // TODO: Run update sequences over cloud update transformer (not necesarry at this point).

  // Add the 'old' key to the spellbook entries
  let updatedProfiles = {};
  for (let [key, value] of Object.entries(action.profiles)) {
    updatedProfiles[key] = {
      ...value,
    };

    // Explicitly set them equal to one another to distinguish whether there are updates
    updatedProfiles[key].old = updatedProfiles[key].current;
  }

  let newState = {
    ...state,
    profiles: {
      ...state.profiles,
      ...updatedProfiles, // overwrite local profiles with cloud profiles & add new cloud profiles
    },
  };

  return newState;
}

function spellCloudSync(state, action) {
  let newState = {
    ...state,
    userSpells: action.spells,
  };

  return newState;
}

/**
 * Update or create a spell draft
 * @param {*} state
 * @param {*} action
 * @returns
 */
function updateUserSpell(state, action) {
  let newState;
  if (state.userSpells) {
    newState = {
      ...state,
      userSpells: {
        ...state.userSpells,
        [action.uuid]: {
          ...action.spell,
          lastModified: new Date().toISOString(),
        },
      },
    };
  } else {
    newState = {
      ...state,
      userSpells: {
        [action.uuid]: {
          ...action.spell,
          lastModified: new Date().toISOString(),
        },
      },
    };
  }
  return newState;
}

/**
 * Delete a spell draft
 * @param {*} state
 * @param {*} action
 * @returns
 */
function deleteUserSpell(state, action) {
  let newState = {
    ...state,
    userSpells: {
      ...state.userSpells,
    },
  };

  delete newState.userSpells[action.uuid];

  return newState;
}

/**
 * Helper functions
 */

function addItem(array, item) {
  let newArray = array.slice();
  newArray.splice(newArray.length, 0, item);
  return newArray;
}

function removeItem(array, item) {
  let newArray = array.slice();
  let index = newArray.indexOf(item);
  newArray.splice(index, 1);
  return newArray;
}
