import { actions } from "../actions/interfaceActions";
import { actions as spellbookActions } from "../actions/spellbookActions";
import {
  combineSpellListCategories,
  combineSpellLists,
} from "../tools/UserSpellTools";

const interfaceData = (
  state = {
    spellslotFilter: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    classesFilter: [],
    spellSearchTerm: "",
    filteredSpells: {},
    spellList: {},
    spellListCategories: {},
    spellListIsDownloading: false,
    spellListCategoryIsDownloading: false,
    spellCardPopover: {
      isOpen: false,
      spell: null,
    },
    showTutorial: false,
    showGuidedTour: false,
    cloudSyncCountdown: 0,
    lastCloudSync: new Date().getTime(),
    isCloudSyncing: false,
    showVerticalSpellList: false,
    showSpellbookGuide: false,
    firstStart: true,
    lastKnownVersion: "0.0.0",
    changelog: {
      version: "0.0.0",
      date: "2024-02-25T20:34:55.462Z",
      highlights: [],
      features: [],
      bugs: [],
    },
  },
  action,
  spellbookData
) => {
  switch (action.type) {
    case actions.CHANGE_SEARCH_TERM:
      return changeSearchTerm(state, action, spellbookData);

    case actions.CHANGE_SPELL_LEVEL_FILTER:
      return changeSpellLevelFilter(state, action, spellbookData);

    case actions.SPELLS_DOWNLOAD_PENDING:
      return setSpellDownloadPending(state, action, spellbookData);

    case actions.SPELLS_CATEGORY_DOWNLOAD_PENDING:
      return setSpellCategoryDownloadPending(state, action, spellbookData);

    case actions.SPELLS_DOWNLOAD_COMPLETE:
      return setSpellList(state, action, spellbookData);

    case actions.SPELLS_CATEGORY_DOWNLOAD_COMPLETE:
      return setSpellListCategories(state, action, spellbookData);

    case actions.SPELL_CARD_POPOVER_SET_STATE:
      return setSpellCardPopoverState(state, action, spellbookData);

    case actions.SET_CLASSES_FILTER:
      return setClassesFilter(state, action, spellbookData);

    case spellbookActions.ADD_CUSTOM_SPELL:
      return updateCustomSpellList(state, action, spellbookData);

    case spellbookActions.REMOVE_CUSTOM_SPELL:
      return updateCustomSpellList(state, action, spellbookData);

    case actions.SET_SPELL_IS_DOWNLOADING:
      return setSpellIsDownloading(state, action, spellbookData);

    case actions.SPELL_DOWNLOAD_FINISHED:
      return spellDownloadFinished(state, action, spellbookData);

    case actions.SET_TUTORIAL_VIEWED:
      return setTutorialViewed(state, action, spellbookData);

    case spellbookActions.SELECT_SPELLBOOK:
      return changeProfile(state, action, spellbookData);

    case spellbookActions.CLOUD_SYNC:
      return cloudSync(state, action, spellbookData);

    case spellbookActions.ADD_SPELLBOOK:
      return addSpellbook(state, action, spellbookData);

    case actions.SET_CLOUD_SYNC_COUNTDOWN:
      return setCloudSyncCountdown(state, action, spellbookData);

    case actions.VERTICAL_SPELL_LIST_CHANGE:
      return setVerticalSpellListSwitch(state, action, spellbookData);

    case actions.SET_SHOW_RECENT_UPDATE:
      return setShowRecentUpdate(state, action, spellbookData);

    case actions.SHOW_SPELLBOOK_GUIDE:
      return showSpellbookGuide(state, action, spellbookData);

    case actions.SET_SPELL_CARD_WIDTH:
      return setSpellCardWidth(state, action, spellbookData);

    case actions.SHOW_FAVORITES_ONLY:
      return showFavoritesOnly(state, action, spellbookData);

    case actions.UPDATE_CHANGELOG:
      return updateChangelog(state, action);

    case actions.SET_CHANGELOG_VIEWED:
      return setChangelogViewed(state, action);

    case actions.SHOW_GUIDED_TOUR:
      return showGuidedTour(state, action);

    default:
      return state;
  }
};

function setTutorialViewed(state, action) {
  let newState = {
    ...state,
    showTutorial: action.value,
  };

  return newState;
}

function setSpellIsDownloading(state, action) {
  let newState = {
    ...state,
    spellList: {
      ...state.spellList,
      [action.spellId]: {
        ...state.spellList[action.spellId],
        isDownloading: true,
        downloadStartDate: new Date().getTime(),
      },
    },
  };

  return newState;
}

function spellDownloadFinished(state, action) {
  let newSpell = {
    ...state.spellList[action.spellId],
    isDownloading: false,
  };

  if (!action.failed) {
    newSpell = {
      ...action.spell,
      isDownloading: false,
    };
  }

  let newState = {
    ...state,
    spellList: {
      ...state.spellList,
      [action.spellId]: newSpell,
    },
  };

  return newState;
}

function setSpellDownloadPending(state, action) {
  let newState = {
    ...state,
    spellListIsDownloading: true,
  };

  return newState;
}

function setSpellCategoryDownloadPending(state, action) {
  let newState = {
    ...state,
    spellListCategoryIsDownloading: true,
  };

  return newState;
}

function setSpellList(state, action) {
  let newState = {
    ...state,
    spellList: action.spells,
    spellListIsDownloading: false,
    spellListLastUpdate: Math.round(new Date().getTime() / 1000),
  };

  return newState;
}

function changeSearchTerm(state, action, spellbookData) {
  let newState = {
    ...state,
    spellSearchTerm: action.searchTerm,
  };
  return refilterSpellList(newState, spellbookData);
}

function changeSpellLevelFilter(state, action, spellbookData) {
  let newFilter = state.spellslotFilter.map((value, index) => {
    if (index === action.spellLevel) {
      return action.value;
    } else {
      return value;
    }
  });

  let newState = {
    ...state,
    spellslotFilter: newFilter,
  };

  return refilterSpellList(newState, spellbookData);
}

function setSpellListCategories(state, action, spellbookData) {
  let newState = {
    ...state,
    filteredSpells: {},
    spellListCategories: {
      ...state.spellListCategories,
      ...action.list,
    },
    spellListCategoryIsDownloading: false,
    spellListCategoriesLastUpdate: Math.round(new Date().getTime() / 1000),
  };

  return refilterSpellList(newState, spellbookData);
}

function updateCustomSpellList(state, action, spellbookState) {
  let newState = {
    ...state,
    spellListCategories: {
      ...state.spellListCategories,
      Custom: {
        ...spellbookState.profiles[spellbookState.selectedProfileId].current
          .customSpells,
      },
    },
  };

  return refilterSpellList(newState, spellbookState);
}

function refilterSpellList(state, spellbookState) {
  let spells = {};

  let favoriteSpells =
    spellbookState.profiles[spellbookState.selectedProfileId].current
      .favoriteSpells;

  let combinedCategorizedSpellList = combineSpellListCategories(
    state.spellListCategories,
    spellbookState.userSpells
  );

  let combinedSpellList = combineSpellLists(
    state.spellList,
    spellbookState.userSpells
  );

  /**
   * This filter function looks for the searchterm and for whether a spell is marked as favorite
   * @param {*} onlyFavorites
   * @param {*} className
   * @param {*} level
   * @returns
   */
  let filterFunction = (onlyFavorites, className, level) => (element) => {
    if (typeof element === "object") element = element.spellId;

    if (
      typeof combinedSpellList[element] !== "undefined" &&
      typeof combinedSpellList[element].name !== "undefined"
    ) {
      if (onlyFavorites) {
        let isFavorite = false;
        if (className === "Custom") {
          isFavorite =
            favoriteSpells[className][level].findIndex((customSpell) => {
              return customSpell.spellId === element;
            }) !== -1;
        } else {
          isFavorite = favoriteSpells[className][level].includes(element);
        }

        return (
          combinedSpellList[element].name
            .toLowerCase()
            .includes(state.spellSearchTerm.toLowerCase()) && isFavorite
        );
      } else {
        return combinedSpellList[element].name
          .toLowerCase()
          .includes(state.spellSearchTerm.toLowerCase());
      }
    } else {
      return false;
    }
  };

  let classesFilter = [...state.classesFilter, "Custom"];

  classesFilter.forEach((className) => {
    let classSpellsByLevel = {};

    if (
      className === "Custom" &&
      (typeof combinedCategorizedSpellList[className] === "undefined" ||
        Object.keys(combinedCategorizedSpellList[className]).length < 1)
    ) {
      return;
    }

    // If all values are false than you should just show every single spelllevel
    if (state.spellslotFilter.every((spellslotVal) => spellslotVal === false)) {
      if (state.spellSearchTerm !== "" || state.showFavoritesOnly) {
        classSpellsByLevel = Object.assign(
          {},
          combinedCategorizedSpellList[className]
        );
        for (let [key, value] of Object.entries(classSpellsByLevel)) {
          classSpellsByLevel[key] = value.filter(
            filterFunction(state.showFavoritesOnly ?? false, className, key)
          );
        }
      } else {
        classSpellsByLevel = Object.assign(
          {},
          combinedCategorizedSpellList[className]
        );
      }
    } else {
      // otherwise iterate over the spell levels and add them if the filter is set to true
      for (
        let spellSlot = 0;
        spellSlot < state.spellslotFilter.length;
        spellSlot++
      ) {
        if (state.spellslotFilter[spellSlot]) {
          classSpellsByLevel["lvl-" + spellSlot] =
            combinedCategorizedSpellList[className]["lvl-" + spellSlot].slice();

          if (state.spellSearchTerm !== "" || state.showFavoritesOnly) {
            classSpellsByLevel["lvl-" + spellSlot] = classSpellsByLevel[
              "lvl-" + spellSlot
            ].filter(
              filterFunction(
                state.showFavoritesOnly ?? false,
                className,
                "lvl-" + spellSlot
              )
            );
          }
        }
      }
    }

    spells[className] = classSpellsByLevel;
  });

  let newState = {
    ...state,
    filteredSpells: spells,
  };

  return newState;
}

function setSpellCardPopoverState(state, action) {
  let newState = {
    ...state,
    spellCardPopover: {
      isOpen: action.isOpen,
      spell: action.spell == null ? state.spellCardPopover.spell : action.spell,
    },
  };

  return newState;
}

function setClassesFilter(state, action, spellbookState) {
  let newState = {
    ...state,
    classesFilter: action.classes,
  };

  return refilterSpellList(newState, spellbookState);
}

function changeProfile(state, action, spellbookData) {
  let spellbookProfile =
    spellbookData.profiles[spellbookData.selectedProfileId].current;

  let customSpells;
  if (typeof spellbookProfile.customSpells !== "undefined") {
    customSpells = spellbookProfile.customSpells;
  } else {
    customSpells = {
      "lvl-0": [],
      "lvl-1": [],
      "lvl-2": [],
      "lvl-3": [],
      "lvl-4": [],
      "lvl-5": [],
      "lvl-6": [],
      "lvl-7": [],
      "lvl-8": [],
      "lvl-9": [],
    };
  }

  let newState = {
    ...state,
    classesFilter:
      spellbookData.profiles[spellbookData.selectedProfileId].current.classes,
    spellslotFilter: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    spellSearchTerm: "",
    spellListCategories: {
      ...state.spellListCategories,
      Custom: {
        ...customSpells,
      },
    },
  };

  return refilterSpellList(newState, spellbookData);
}

function cloudSync(state, action, spellbookData) {
  let newState = {
    ...state,
    lastCloudSync: new Date().getTime(),
    isCloudSyncing: false,
  };

  return newState;
}

function setCloudSyncCountdown(state, action) {
  return {
    ...state,
    cloudSyncCountdown: action.count,
  };
}

function setVerticalSpellListSwitch(state, action) {
  return {
    ...state,
    showVerticalSpellList: action.show,
  };
}

function setShowRecentUpdate(state, action) {
  return {
    ...state,
    showRecentUpdate: action.show,
  };
}

function showSpellbookGuide(state, action) {
  return {
    ...state,
    showSpellbookGuide: action.show,
  };
}

function addSpellbook(state, action) {
  return showSpellbookGuide(state, { show: true });
}

function setSpellCardWidth(state, action) {
  return {
    ...state,
    spellCardWidth: action.width,
  };
}

function showFavoritesOnly(state, action, spellbookData) {
  let newState = {
    ...state,
    showFavoritesOnly: action.favoritesOnly,
  };

  return refilterSpellList(newState, spellbookData);
}

function updateChangelog(state, action) {
  return {
    ...state,
    changelog: action.changelog,
  };
}

function setChangelogViewed(state, action) {
  return {
    ...state,
    lastKnownVersion: action.version,
  };
}

function showGuidedTour(state, action) {
  return {
    ...state,
    showGuidedTour: action.show,
  };
}

export { interfaceData };
