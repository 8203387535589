import React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";

import { compose } from "redux";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Drawer, Grid, IconButton } from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const styles = (theme: Theme) => ({
  root: {
    color: "#000",
    minWidth: 400,
    width: "30vw",
    height: "100%",
    padding: theme.spacing(2),
  },
  gridItem: {
    paddingTop: theme.spacing(2),
    width: "100%",
    overflowY: "auto" as "auto",
    overflowX: "hidden" as "hidden",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

export interface DrawerPageProps {
  handleClose: () => void;
  classes: ClassNameMap;
  open: boolean;
  title?: string;
  hideCloseButton?: boolean;
  children: React.ReactNode;
}

function DrawerPage(props: DrawerPageProps) {
  const { classes, open, handleClose, title, children } = props;

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Grid className={classes.root} container direction="column">
        {props.hideCloseButton && typeof title !== "string" ? (
          <></>
        ) : (
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h2>{title}</h2>
            </Grid>
            {props.hideCloseButton ? (
              <></>
            ) : (
              <Grid item>
                <IconButton
                  edge="start"
                  className={classes.closeButton}
                  aria-label="openSpellList"
                  onClick={handleClose}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
        <Grid xs className={classes.gridItem} item>
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default compose<React.FunctionComponent<Partial<DrawerPageProps>>>(
  withStyles(styles)
)(DrawerPage);
