import React, { ReactNode } from "react";
import {
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import { ClassNameMap } from "@material-ui/styles";
import { theme as defaultTheme } from "../themes/theme";
import useDeviceType, { DeviceType } from "../tools/DeviceInfo";
import FullscreenDialog from "./FullscreenDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const themeData = JSON.parse(JSON.stringify(defaultTheme));
themeData.palette.type = "light";
themeData.palette.text.primary = defaultTheme.palette!.background!.default;

const muiLightTheme = createTheme(themeData);

const styles = (theme: Theme) =>
  createStyles({
    desktopGridRoot: {
      height: "100%",
    },
    desktopTitleStyle: {
      fontSize: 24,
      fontWeight: 600,
      height: 40,
      marginBottom: theme.spacing(2),
    },
    desktopHeader: {
      width: "100%",
    },
  });

interface DevicePopupProps {
  classes: ClassNameMap;
  isOpen: boolean;
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
}

function DevicePopup(props: DevicePopupProps) {
  let { classes } = props;

  let { deviceType, width, height } = useDeviceType();

  let anchorElTutorial = {
    clientWidth: 10,
    clientHeight: 10,
    getBoundingClientRect: () => ({
      bottom: height / 2 - 350,
      top: height / 2 - 400,
      left: width / 2,
      right: width / 2,
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      toJSON: () => {},
    }),
  };

  return deviceType === DeviceType.MOBILE ? (
    <ThemeProvider theme={muiLightTheme}>
      <FullscreenDialog
        title={props.title}
        open={props.isOpen}
        handleClose={props.onClose}
        hideCloseButton={props.onClose === undefined}
      >
        {props.children}
      </FullscreenDialog>
    </ThemeProvider>
  ) : (
    <React.Fragment>
      {props.isOpen ? (
        <div
          style={{
            //zIndex: 1450,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        ></div>
      ) : (
        ""
      )}
      <Popper
        style={{
          //zIndex: 1500,
          width: "100%",
          height: "100%",
          maxHeight: 800,
          maxWidth: 700,
        }}
        open={props.isOpen}
        anchorEl={anchorElTutorial}
        transition
        placement="top"
      >
        {({ TransitionProps }) => (
          <ThemeProvider theme={muiLightTheme}>
            <Fade {...TransitionProps} timeout={100}>
              <Paper
                style={{
                  width: "100%",
                  height: "100%",
                  padding: 24,
                }}
                className={classes.popper}
              >
                <Grid
                  className={classes.desktopGridRoot}
                  direction="column"
                  container
                >
                  {props.title || props.onClose ? (
                    <Grid
                      className={classes.desktopHeader}
                      direction="row"
                      justifyContent="space-between"
                      item
                      container
                    >
                      <Grid className={classes.desktopTitleStyle} item>
                        {props.title}
                      </Grid>
                      {props.onClose ? (
                        <Grid item>
                          <IconButton
                            edge="start"
                            className={classes.closeButton}
                            aria-label="openSpellList"
                            onClick={props.onClose}
                          >
                            <FontAwesomeIcon icon={faTimes as IconProp} />
                          </IconButton>
                        </Grid>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid xs item>
                    {props.children}
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          </ThemeProvider>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default compose<React.FunctionComponent<Partial<DevicePopupProps>>>(
  withStyles(styles)
)(DevicePopup);
