import React, { useState } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";
import {
  Button,
  Grid,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import DevicePopup from "./DevicePopup";
import useDeviceType from "../tools/DeviceInfo";
import {
  setTutorialViewed,
  showSpellbookGuide,
  setChangelogViewed,
  showGuidedTour,
} from "../actions/interfaceActions";
import { ChangelogResponse } from "../tools/ApiManager";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      //margin: theme.spacing(2),
    },
    rootGrid: {
      height: "100%",
    },
    linkText: {
      color: "#0000EE",
      cursor: "pointer",
      textDecoration: "underline",
    },
  });

interface ChangelogProps {
  classes: ClassNameMap;
  showChangelog: boolean;
  setTutorialViewed: (value?: boolean) => void;
  showSpellbookGuide: (value?: boolean) => void;
  setChangelogViewed: (version: string) => void;
  showGuidedTour: (show: boolean) => void;
  changelog: ChangelogResponse;
}

export const Changelog: React.FunctionComponent<ChangelogProps> = (
  props: ChangelogProps
) => {
  let { deviceType } = useDeviceType();
  let isMobile = deviceType === "MOBILE";

  const [changelogOpen, setChangelogOpen] = useState(false);

  if (typeof props.changelog == "undefined") return <></>;

  let onClose = () => {
    props.setChangelogViewed(props.changelog.version);
  };

  let showChangelog = props.showChangelog;

  return (
    <React.Fragment>
      <DevicePopup onClose={onClose} isOpen={showChangelog} title="Welcome!">
        {/* <TutorialPages /> */}
        <Grid
          className={props.classes.rootGrid}
          direction="column"
          justifyContent="space-between"
          alignContent="center"
          spacing={1}
          container
        >
          <Grid
            style={{ textAlign: "center" }}
            spacing={2}
            direction="column"
            item
            container
          >
            <Grid item>
              <Typography variant={isMobile ? "h3" : "h2"} color="primary">
                v{props.changelog.version ? props.changelog.version : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Last updated on{" "}
                {props.changelog.date
                  ? new Date(props.changelog.date).toLocaleDateString()
                  : ""}
                .{" "}
                <div
                  className={props.classes.linkText}
                  onClick={() => setChangelogOpen(true)}
                >
                  Click here see all the changes in this version
                </div>
              </Typography>
            </Grid>
            <Grid item>
              {props.changelog.highlights.length > 0 ? (
                <Grid spacing={2} direction="column" container>
                  {props.changelog.highlights.map((highlight, index) => (
                    <Grid item key={index}>
                      {highlight}
                    </Grid>
                  ))}
                </Grid>
              ) : undefined}
            </Grid>
          </Grid>
          <Grid style={{ textAlign: "center" }} item>
            You can start using the tool or push the button below to guide you
            through setting up your first spellbook!
            <br />
            <br />
            <Grid direction="row" spacing={2} justifyContent="center" container>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    props.setChangelogViewed(props.changelog.version);
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    props.setChangelogViewed(props.changelog.version);
                    props.showGuidedTour(true);
                  }}
                >
                  Take a guided tour (new)
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.setChangelogViewed(props.changelog.version);
                    props.showSpellbookGuide(true);
                  }}
                >
                  Set-up your first spellbook
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DevicePopup>
      <DevicePopup
        isOpen={changelogOpen}
        onClose={() => setChangelogOpen(false)}
        title="Changelog"
      >
        <Grid direction="column" container>
          <Grid item>
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              color="primary"
            >
              Features
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              {props.changelog.highlights.map((feature, index) => (
                <li key={index}>
                  <b>{feature}</b>
                </li>
              ))}
              {props.changelog.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </Grid>
          <Grid item>
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              color="primary"
            >
              Bugs
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              {props.changelog.bugs.map((bug, index) => (
                <li key={index}>{bug}</li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </DevicePopup>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  setTutorialViewed,
  showSpellbookGuide,
  setChangelogViewed,
  showGuidedTour,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  showChangelog:
    state.interfaceData.lastKnownVersion !==
    state.interfaceData.changelog?.version,
  changelog: state.interfaceData.changelog,
});

export default compose<React.FunctionComponent<Partial<ChangelogProps>>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Changelog);
