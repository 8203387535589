import { UserSpell } from "../containers/CustomSpells/CustomSpellInterface";
import {
  ClassOrderedSpellList,
  Spell,
  SpellList,
} from "../reducers/interfaceData";

/**
 * Transforms and combines the spell lists from the user and the spell list
 * @param spell
 * @param userSpells
 */
export function combineSpellLists(
  spells: SpellList,
  userSpells?: {
    [id: string]: UserSpell;
  }
) {
  let combinedSpellList: { [id: string]: Spell } = { ...spells };

  if (typeof userSpells === "undefined") {
    return combinedSpellList;
  }

  // Add user spells to the spell list
  for (let [userSpellId, userSpell] of Object.entries(userSpells)) {
    let newSpell = userSpellToSpell(userSpellId, userSpell);
    if (typeof newSpell !== "undefined") {
      combinedSpellList[userSpellId] = newSpell;
    }
  }

  return combinedSpellList;
}

/**
 * Transforms and combines the spell list categories from the user and the category spell list
 * @param spellListCategories
 * @param userSpells
 * @returns
 */
export function combineSpellListCategories(
  spellListCategories: ClassOrderedSpellList,
  userSpells?: { [id: string]: UserSpell }
) {
  let combinedSpellListCategories: ClassOrderedSpellList = {
    ...spellListCategories,
  };

  if (typeof userSpells === "undefined") {
    return combinedSpellListCategories;
  }

  for (let [userSpellId, userSpell] of Object.entries(userSpells)) {
    if (userSpell.data !== undefined) {
      for (let [className, level] of Object.entries(
        userSpell.data?.spellLevels || {}
      )) {
        combinedSpellListCategories[className] = {
          ...combinedSpellListCategories[className],
          [`lvl-${level}`]: [
            ...combinedSpellListCategories[className][`lvl-${level}`],
            userSpellId,
          ],
        };
      }
    }
  }

  return combinedSpellListCategories;
}

/**
 * Transform a user spell to a regular spell format
 * @param userSpell
 * @returns
 */
export function userSpellToSpell(
  uuid: string,
  userSpell: UserSpell
): Spell | undefined {
  if (typeof userSpell.data === "undefined") {
    return undefined;
  }

  let spell_level_text = "";

  // Spell like ability level is the lowest class level
  let SLA_level = 9;

  // Create spell level text
  for (let [key, value] of Object.entries(userSpell.data.spellLevels)) {
    if (
      key === "Sorcerer" &&
      userSpell.data.spellLevels["Wizard"] === undefined
    ) {
      spell_level_text += `Sorcerer/Wizard ${value}, `;
      userSpell.data.spellLevels["Wizard"] = value;
    } else if (key === "Sorcerer") {
      // do nothing
      continue;
    } else if (key === "Wizard") {
      spell_level_text += `Sorcerer/Wizard ${value}, `;
      userSpell.data.spellLevels["Sorcerer"] = value;
    } else if (
      key === "Oracle" &&
      userSpell.data.spellLevels["Cleric"] === undefined
    ) {
      spell_level_text += `Cleric/Oracle ${value}, `;
      userSpell.data.spellLevels["Cleric"] = value;
    } else if (key === "Oracle") {
      // do nothing
      continue;
    } else if (key === "Cleric") {
      spell_level_text += `Cleric/Oracle ${value}, `;
      userSpell.data.spellLevels["Oracle"] = value;
    } else {
      spell_level_text += `${key} ${value}, `;
    }

    if (value && value < SLA_level) SLA_level = value;
  }

  // Remove trailing comma
  spell_level_text = spell_level_text.slice(0, -2);

  let components_text = "";
  if (userSpell.data.components.verbal) components_text += "V";
  if (userSpell.data.components.somatic) components_text += ", S";
  if (userSpell.data.components.material) {
    // If material is present, add M
    components_text += ", M";
    // If material components are present, add the text
    if (userSpell.data.components.materialComponents)
      components_text += ` (${userSpell.data.components.materialComponents})`;
  }
  if (userSpell.data.components.focus) components_text += ", F";
  if (userSpell.data.components.divineFocus) components_text += ", DF";

  let transformedSpell: Spell = {
    id: uuid,
    name: userSpell.data.name,
    descriptor: "",
    spell_level_text: spell_level_text,
    casting_time_text: userSpell.data.castingTime,
    components_text: components_text,
    range_text: userSpell.data.range,
    area_text:
      userSpell.data.area && userSpell.areaSpell ? userSpell.data.area : "",
    effect_text: "",
    targets_text:
      userSpell.data.targets && !userSpell.areaSpell
        ? userSpell.data.targets
        : "",
    duration_text: userSpell.data.duration,
    description: userSpell.data.description,
    short_description: userSpell.data.shortDescription,
    costly_components: "0",
    material_costs: "0",
    verbal: userSpell.data.components.verbal ? "1" : "0",
    somatic: userSpell.data.components.somatic ? "1" : "0",
    material: userSpell.data.components.material ? "1" : "0",
    focus: userSpell.data.components.focus ? "1" : "0",
    divine_focus: userSpell.data.components.divineFocus ? "1" : "0",
    dismissible: userSpell.data.dismissible ? "1" : "0",
    shapeable: userSpell.data.shapeable ? "1" : "0",
    saving_throw: userSpell.data.savingThrow,
    spell_resistance: userSpell.data.spellResistance,
    SLA_level: `${SLA_level}`,
    source: "172",
    domain: "",
    bloodline: "",
    patron: "",
    augmented: "",
    haunt_statistics: "",
    mythic: "0",
    mythic_text: "",
    ruse: "0",
    draconic: "0",
    meditative: "0",
    language_dependent: "0",
    mind_affecting: userSpell.data.mindAffecting ? "1" : "0",
    school: userSpell.data.school,
    sub_school: "",
    isUserSpell: true,
    owner: `${userSpell.owner}`,
  };

  // TODO: Do transformation

  return transformedSpell;
}
