import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isLoggedIn, getUser, refreshToken, logOut } from "../tools/Account";

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: "2px 2px 2px 2px",
    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.60)",
    cursor: "pointer",
    maxWidth: 200,
  },
  iconGridItem: {
    width: 40,
    height: 40,
  },
  textGridItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: "#757575",
    fontWeight: 600,
  },
  logoutLink: {
    color: "#48dbfb",
  },
}));

export default function GoogleLoginComponent(props) {
  refreshToken();
  let classes = useStyles();
  let name = getUser().name;

  let handleLoginClick = (e) => {
    window.location.href =
      API_URL + "/v1/user/login?returnUrl=" + process.env.REACT_APP_URL;
  };

  let handleLogoutClick = (e) => {
    logOut();
  };

  let loginButton = (
    <Grid
      onClick={handleLoginClick}
      className={classes.root}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      container
    >
      <Grid className={classes.iconGridItem} item>
        <img
          className={classes.iconGridItem}
          alt="Google Logo"
          src="images/btn_google.svg"
        />
      </Grid>
      <Grid className={classes.textGridItem} item>
        Sign in with Google
      </Grid>
    </Grid>
  );

  let logoutButton = (
    <div>
      You are logged in as {name}!{" "}
      <a className={classes.logoutLink} onClick={handleLogoutClick} href="#">
        Logout?
      </a>
    </div>
  );

  return (
    <div className={props.className}>
      {isLoggedIn() ? logoutButton : loginButton}
    </div>
  );
}
