import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  iconContainer: {
    width: 42,
    textAlign: "center",
  },
  subtitleContainer: {
    color: "#888",
  },
}));

export default function CustomCardHeader(props) {
  let classes = useStyles();

  return (
    <Grid
      className={classes.root}
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      container
    >
      <Grid className={classes.iconContainer} item>
        {props.icon}
      </Grid>
      <Grid direction="column" container item xs>
        <Grid item>{props.title}</Grid>
        <Grid className={classes.subtitleContainer} item>
          {props.subTitle}
        </Grid>
      </Grid>
      <Grid item>{props.action}</Grid>
    </Grid>
  );
}
