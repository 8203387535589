import casterNames from "../data/names.json";
import { deleteUserSpell as apiDeleteUserSpell } from "../tools/ApiManager";
export const actions = {
  LEARN_SPELL: "LEARN_SPELL",
  UNLEARN_SPELL: "UNLEARN_SPELL",
  SET_SPELL_SLOTS: "SET_SPELL_SLOTS",
  USE_SPELL_SLOT: "USE_SPELL_SLOT",
  FREE_SPELL_SLOT: "FREE_SPELL_SLOT",
  RESET_SPELL_SLOTS: "RESET_SPELL_SLOTS",
  SET_SPELLBOOK_NAME: "SET_SPELLBOOK_NAME",
  ADD_CLASS: "ADD_CLASS",
  REMOVE_CLASS: "REMOVE_CLASS",
  TOGGLE_FAVORITE_SPELL: "TOGGLE_FAVORITE_SPELL",
  SELECT_SPELLBOOK: "SELECT_SPELLBOOK",
  ADD_SPELLBOOK: "ADD_SPELLBOOK",
  ADJUST_SPELL_PREPARATIONS: "ADJUST_SPELL_PREPARATIONS",
  ADD_CUSTOM_SPELL: "ADD_CUSTOM_SPELL",
  REMOVE_CUSTOM_SPELL: "REMOVE_CUSTOM_SPELL",
  CLOUD_SYNC: "CLOUD_SYNC",
  SPELL_CLOUD_SYNC: "SPELL_CLOUD_SYNC",
  PROFILE_TO_CLOUD: "PROFILE_TO_CLOUD",
  PROFILE_TO_LOCAL: "PROFILE_TO_LOCAL",
  UPDATE_USER_SPELL: "UPDATE_USER_SPELL",
  DELETE_USER_SPELL: "DELETE_USER_SPELL",
};

export const addSpellbook = () => {
  return (dispatch) => {
    let name = casterNames[Math.ceil(casterNames.length * Math.random()) - 1];

    dispatch({
      type: actions.ADD_SPELLBOOK,
      spellbook: {
        name: name,
        classes: [],
        favoriteSpells: {},
        learnedSpells: {
          cantrip: [],
          first: [],
          second: [],
          third: [],
          fourth: [],
          fifth: [],
          sixth: [],
          seventh: [],
          eighth: [],
          ninth: [],
        },
        spellSlots: [
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
          { slots: 0, used: 0 },
        ],
      },
    });
  };
};

export const selectSpellbook = (spellBookId) => {
  return (dispatch) => {
    dispatch({
      type: actions.SELECT_SPELLBOOK,
      spellbookId: spellBookId,
    });
  };
};

export const toggleFavoriteSpell = (spellId, spellClass, spellLevel) => {
  return (dispatch) => {
    dispatch({
      type: actions.TOGGLE_FAVORITE_SPELL,
      spellId: spellId,
      spellClass: spellClass,
      spellLevel: spellLevel,
    });
  };
};

export const setSpellbookName = (name) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SPELLBOOK_NAME,
      name: name,
    });
  };
};

export const setSpellSlots = (spellslot, count) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SPELL_SLOTS,
      spellslot: spellslot,
      count: count,
    });
  };
};

export const setSpellSlotUsed = (level) => {
  return (dispatch) => {
    dispatch({
      type: actions.USE_SPELL_SLOT,
      level: level,
    });
  };
};

export const setSpellSlotFree = (level) => {
  return (dispatch) => {
    dispatch({
      type: actions.FREE_SPELL_SLOT,
      level: level,
    });
  };
};

export const resetSpellSlots = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_SPELL_SLOTS,
    });
  };
};

export const addClass = (className) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_CLASS,
      className: className,
    });
  };
};

export const removeClass = (className) => {
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_CLASS,
      className: className,
    });
  };
};

export const learnSpell = (level, className, spellId) => {
  return (dispatch) => {
    dispatch({
      type: actions.LEARN_SPELL,
      level: level,
      spellId: spellId,
      className: className,
    });
  };
};

export const unlearnSpell = (level, className, spellId) => {
  return (dispatch) => {
    dispatch({
      type: actions.UNLEARN_SPELL,
      level: level,
      spellId: spellId,
      className: className,
    });
  };
};

export const adjustSpellPreparations = (
  level,
  className,
  spellId,
  adjustment
) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADJUST_SPELL_PREPARATIONS,
      level: level,
      spellId: spellId,
      className: className,
      adjustment: adjustment,
    });
  };
};

export const addCustomSpell = (spellId, spellLevel, isPrepared) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_CUSTOM_SPELL,
      spellId: spellId,
      spellLevel: spellLevel,
      isPrepared: isPrepared,
    });
  };
};

export const removeCustomSpell = (spellId, spellLevel, isPrepared) => {
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_CUSTOM_SPELL,
      spellId: spellId,
      spellLevel: spellLevel,
      isPrepared: isPrepared,
    });
  };
};

export const moveProfileToCloud = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_TO_CLOUD,
      profileId: profileId,
    });
  };
};

export const moveProfileToLocal = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_TO_LOCAL,
      profileId: profileId,
    });
  };
};

export const updateUserSpell = (uuid, spell) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_USER_SPELL,
      uuid: uuid,
      spell: spell,
    });
  };
};

export const deleteUserSpell = (uuid) => {
  apiDeleteUserSpell(uuid);

  return (dispatch) => {
    dispatch({
      type: actions.DELETE_USER_SPELL,
      uuid: uuid,
    });
  };
};
