import React, { useEffect, useState } from "react";
import FullscreenDialog from "./FullscreenDialog";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { theme as defaultTheme } from "../themes/theme";
import {
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Snackbar,
  Theme,
  withStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import GoogleLoginComponent from "../components/GoogleLoginComponent";
import SpellbookSettings from "./Settings/SpellbookSettings";
import SpellSlotSettings from "./Settings/SpellSlotSettings";
import SpellListContainer from "./SpellListDrawerContainer";
import ProfilesContainer from "./Settings/ProfilesContainer";

import { resetSpellSlots } from "../actions/spellbookActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { State } from "../reducers";
import TextIconButton from "../components/TextIconButton";
import CustomSpellOverview from "./CustomSpells/CustomSpellOverview";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import useDeviceType, { DeviceType } from "../tools/DeviceInfo";
import DrawerPage from "./DrawerPage";
import InterfaceSettings from "./Settings/InterfaceSettings";
import TourManager, { TourEvents } from "../tools/TourManager";
import { setChangelogViewed } from "../actions/interfaceActions";
import { SpellbookProfileData } from "../reducers/spellbookData";
import { combineSpellLists } from "../tools/UserSpellTools";
import { SpellList } from "../reducers/interfaceData";

library.add(fas);

const themeData = JSON.parse(JSON.stringify(defaultTheme));
themeData.palette.type = "light";
themeData.palette.text.primary = "#000";

const muiLightTheme = createTheme(themeData);

let styles = (theme: Theme) => ({
  subMenuItem: {
    paddingLeft: theme.spacing(4),
  },
  mainGrid: {
    height: "100%",
  },
  hotkeyGridItem: {
    padding: theme.spacing(2),
  },
  menuGridItem: {
    width: "100%",
  },
  googleLoginComponent: {
    paddingBottom: theme.spacing(4),
  },
  menuDrawer: {
    minWidth: 400,
  },
});

interface MobileMenuProps {
  classes: ClassNameMap;
  menuOpened: boolean;
  profile: SpellbookProfileData;
  spellList: SpellList;
  handleMenuClose: () => void;
  resetSpellSlots: () => void;
  setChangelogViewed: (version: string) => void;
}

function MobileMenu({
  menuOpened,
  handleMenuClose,
  classes,
  resetSpellSlots,
  setChangelogViewed,
  profile,
  spellList,
}: MobileMenuProps) {
  const [pageStack, setPageStackState] = useState<
    Array<{
      open: boolean;
      title: string;
      element?: React.JSX.Element;
    }>
  >([]);

  let tourManager = TourManager.getInstance();

  useEffect(() => {
    let subscription = tourManager.subscribe(
      (event: TourEvents, item: string) => {
        // If the tour requests us to close the spellbook settings, we pop the page stack
        if (
          event === TourEvents.CLOSE_REQUEST &&
          item === "spellbook-settings"
        ) {
          let newPageStack = [...pageStack];
          newPageStack.pop();
          setPageStackState([...newPageStack]);
        } else if (event === TourEvents.CLOSE_REQUEST && item === "spells") {
          let newPageStack = [...pageStack];
          newPageStack.pop();
          setPageStackState([...newPageStack]);
        }
      }
    );
    return () => {
      tourManager.unsubscribe(subscription);
    };
  }, [tourManager, pageStack]);

  const handleMenuItemClick =
    (title: string, element: React.JSX.Element) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setPageStackState((currentState) => [
        ...currentState,
        { open: true, title: title, element: element },
      ]);
    };

  let device = useDeviceType();

  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  /**
   * Copy spells to clipboard
   */
  let copySpellsToClipboard = () => {
    if (typeof profile?.learnedSpells == "undefined") {
      return;
    }

    let htmlText = "Spells of " + profile.name + "<br><br>";
    let text = "Spells of " + profile.name + "\n\n";

    let addToClipboardText = (spellLevel: string, levelName: string) => {
      if (
        profile.learnedSpells[spellLevel] &&
        profile.learnedSpells[spellLevel].length > 0
      ) {
        htmlText += `<b>${levelName}:</b><br>`;
        text += `${levelName}:\n`;
        for (let spell of profile.learnedSpells[spellLevel]) {
          htmlText += spellList[spell.spellId].name + "<br>";
          text += spellList[spell.spellId].name + "\n";
        }
        htmlText += "<br>";
        text += "\n";
      }
    };

    addToClipboardText("cantrip", "Cantrips");
    addToClipboardText("first", "1st level spells");
    addToClipboardText("second", "2nd level spells");
    addToClipboardText("third", "3rd level spells");
    addToClipboardText("fourth", "4th level spells");
    addToClipboardText("fifth", "5th level spells");
    addToClipboardText("sixth", "6th level spells");
    addToClipboardText("seventh", "7th level spells");
    addToClipboardText("eighth", "8th level spells");
    addToClipboardText("ninth", "9th level spells");

    let clipboardItem = new ClipboardItem({
      "text/html": new Blob([htmlText], { type: "text/html" }),
      "text/plain": new Blob([text], { type: "text/plain" }),
    });
    navigator.clipboard.write([clipboardItem]);

    // Show snackbar
    setCopiedSnackbarOpen(true);

    // Hide snackbar after 3 seconds
    setTimeout(() => {
      setCopiedSnackbarOpen(false);
    }, 3000);
  };

  let menuContent = (
    <Grid
      container
      className={classes.mainGrid}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      key={"menu-content"}
    >
      <Grid className={classes.menuGridItem} item>
        <Grid
          className={classes.hotkeyGridItem}
          container
          spacing={2}
          direction="row"
          justifyContent={
            device.deviceType === DeviceType.MOBILE
              ? "space-between"
              : "space-evenly"
          }
          alignItems="center"
        >
          <Grid item>
            <TextIconButton
              title="Rest"
              icon={"sync-alt"}
              className="tour-reset-spells"
              onClick={() => {
                resetSpellSlots();
                handleMenuClose();
              }}
            />
          </Grid>
          <Grid item>
            <TextIconButton
              title="Spellbook"
              className="tour-spellbook-button"
              icon={"users-cog"}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                tourManager.clickItem("spellbook-button");
                handleMenuItemClick("Spellbook", <SpellbookSettings />)(event);
              }}
            />
          </Grid>
          <Grid item>
            <TextIconButton
              title="Spellslots"
              className="tour-spellslots-button"
              icon={"magic"}
              onClick={handleMenuItemClick(
                "Spell slots",
                <SpellSlotSettings />
              )}
            />
          </Grid>
          <Grid item>
            <TextIconButton
              title="Spells"
              className="tour-spells-button"
              icon={"book"}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                tourManager.clickItem("spells-button");
                handleMenuItemClick(
                  "Spells",
                  <SpellListContainer isOpen={true} />
                )(event);
              }}
            />
          </Grid>
        </Grid>

        <MenuList>
          <Divider />
          <MenuItem
            className="tour-custom-spells"
            onClick={handleMenuItemClick(
              "Custom spells",
              <CustomSpellOverview openView={handleMenuItemClick} />
            )}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="hat-wizard" />
            </ListItemIcon>
            <ListItemText>Custom spells</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            className="tour-spell-books"
            onClick={handleMenuItemClick(
              "Spellbooks",
              <ProfilesContainer
                openSettings={() => {
                  handleMenuItemClick("Spellbooks", <SpellbookSettings />);
                }}
              />
            )}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="user-friends" />
            </ListItemIcon>
            <ListItemText>Change spellbook</ListItemText>
          </MenuItem>
          <Divider />
          {device.deviceType !== DeviceType.MOBILE
            ? [
                <MenuItem
                  onClick={handleMenuItemClick(
                    "Interface settings",
                    <InterfaceSettings />
                  )}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon="cog" />
                  </ListItemIcon>
                  <ListItemText>Interface settings</ListItemText>
                </MenuItem>,
                <Divider />,
              ]
            : undefined}
          <MenuItem
            onClick={() => {
              copySpellsToClipboard();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="clipboard-list" />
            </ListItemIcon>
            <ListItemText>Copy spells to clipboard</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setChangelogViewed("0.0.0");
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="circle-info" />
            </ListItemIcon>
            <ListItemText>Changelog & Help</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLScCG7KJHnzLErMK_j0dGmgBxKZsYRZdpnR39KSTIwCdTEYkaw/viewform?usp=sf_link",
                "_blank"
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="bug" />
            </ListItemIcon>
            <ListItemText>Bugs and features</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              window.open(
                "https://paypal.me/miscoria?country.x=NL&locale.x=en_US",
                "_blank"
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="dollar-sign" />
            </ListItemIcon>
            <ListItemText>Donate!</ListItemText>
          </MenuItem>
          <Divider />
        </MenuList>
      </Grid>

      <Grid className={classes.googleLoginComponent} item>
        <GoogleLoginComponent />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={copiedSnackbarOpen}
        message={"Copied to clipboard!"}
        key="CopiedSnackbar"
      />
      {/* <Grid item>
        <Typography style={{ textAlign: "center" }} variant="body1">
          If you like this tool please consider{" "}
          <a
            href="https://paypal.me/miscoriadev"
            rel="noopener noreferrer"
            target="_blank"
          >
            donating
          </a>
          , this tool is made by just one person and donating motivates me to
          add features and remove bugs!
        </Typography>
      </Grid> */}
    </Grid>
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={muiLightTheme}>
        {device.deviceType !== DeviceType.MOBILE ? (
          <>
            {pageStack.length > 0 ? (
              <DrawerPage
                open={pageStack[pageStack.length - 1].open}
                handleClose={() => {
                  let newPageStack = [...pageStack];
                  newPageStack.pop();
                  setPageStackState([...newPageStack]);
                }}
                title={pageStack[pageStack.length - 1].title}
              >
                {React.cloneElement(pageStack[pageStack.length - 1].element!, {
                  closeView: () => {
                    let newPageStack = [...pageStack];
                    newPageStack.pop();
                    setPageStackState([...newPageStack]);
                  },
                })}
              </DrawerPage>
            ) : (
              <DrawerPage
                open={menuOpened}
                handleClose={handleMenuClose}
                title="Menu"
              >
                {menuContent}
              </DrawerPage>
            )}
          </>
        ) : (
          <>
            {pageStack.length > 0 ? (
              <FullscreenDialog
                open={pageStack[pageStack.length - 1].open}
                handleClose={() => {
                  let newPageStack = [...pageStack];
                  newPageStack.pop();
                  setPageStackState([...newPageStack]);
                }}
                title={pageStack[pageStack.length - 1].title}
              >
                {React.cloneElement(pageStack[pageStack.length - 1].element!, {
                  closeView: () => {
                    let newPageStack = [...pageStack];
                    newPageStack.pop();
                    setPageStackState([...newPageStack]);
                  },
                })}
              </FullscreenDialog>
            ) : (
              <FullscreenDialog
                open={menuOpened}
                handleClose={handleMenuClose}
                title="Menu"
              >
                {menuContent}
              </FullscreenDialog>
            )}
          </>
        )}
      </ThemeProvider>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  resetSpellSlots,
  setChangelogViewed,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MobileMenu);
