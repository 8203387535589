import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { Theme, withStyles } from "@material-ui/core/styles";

import { compose } from "redux";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Grid, IconButton } from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = (theme: Theme) => ({
  root: {
    color: "#000",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

export interface FullscreenDialogProps {
  handleClose: () => void;
  classes: ClassNameMap;
  open: boolean;
  title?: string;
  hideCloseButton?: boolean;
  children: React.ReactNode;
}

function FullscreenDialog(props: FullscreenDialogProps) {
  const { classes, open, handleClose, title, children } = props;

  return (
    <Dialog
      fullScreen
      className={classes.root}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      {props.hideCloseButton && typeof title !== "string" ? (
        <></>
      ) : (
        <DialogTitle className={classes.root}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{title}</Grid>
            {props.hideCloseButton ? (
              <></>
            ) : (
              <Grid item>
                <IconButton
                  edge="start"
                  className={classes.closeButton}
                  aria-label="openSpellList"
                  onClick={handleClose}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default compose<React.FunctionComponent<Partial<FullscreenDialogProps>>>(
  withStyles(styles)
)(FullscreenDialog);
