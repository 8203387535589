import { Grid, Theme, Typography } from "@material-ui/core";
import React from "react";
import SpellslotSelector from "../../components/SpellslotSelector";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { SpellbookProfileData } from "../../reducers/spellbookData";
import { setSpellSlots } from "../../actions/spellbookActions";
import { combineSpellListCategories } from "../../tools/UserSpellTools";
const styles = (theme: Theme) => ({
  root: {
    width: 650,
  },
  firstTab: {
    width: 650,
  },
  spellbookName: {
    width: 300,
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  helpText: {
    color: "#888",
  },
  classSelector: {
    width: 300,
  },
  addButton: {
    width: 100,
  },
});

interface SpellSlotSettingsProps {
  classes: ClassNameMap;
  profile: SpellbookProfileData;
  setSpellSlots: (spellLevel: number, slots: number) => void;
}

function SpellSlotSettings(props: SpellSlotSettingsProps) {
  let { classes } = props;

  const onSpellSlotChange = (event: any, value: any, spellLevel: any) => {
    if (value == null) value = "0";
    props.setSpellSlots(spellLevel, value);
  };

  let getSlotStringValue = (slot: number) => {
    return props.profile.spellSlots[slot].slots !== null &&
      props.profile.spellSlots[slot].slots !== undefined
      ? props.profile.spellSlots[slot].slots.toString()
      : "0";
  };

  return (
    <Grid
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      container
    >
      <Grid item>
        <Typography className={classes.helpText}>
          You can set-up how many spellslots you have here.
        </Typography>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              1st level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={1}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(0)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              2nd level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={2}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(1)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              3rd level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={3}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(2)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              4th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={4}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(3)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              5th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={5}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(4)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              6th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={6}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(5)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              7th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={7}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(6)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              8th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={8}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(7)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ width: 300 }} item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item xs>
            <Typography className={classes.text}>
              9th level spellslots
            </Typography>
          </Grid>
          <Grid item>
            <SpellslotSelector
              spellLevel={9}
              onChange={onSpellSlotChange}
              defaultValue={getSlotStringValue(8)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  setSpellSlots,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellListCategories: combineSpellListCategories(
    state.interfaceData.spellListCategories,
    state.spellbookData.userSpells
  ),
});

export default compose<React.FunctionComponent>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellSlotSettings);
