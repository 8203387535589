import React, { useRef, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
  Theme,
  createStyles,
} from "@material-ui/core";
import SpellListContainer from "./SpellListContainer";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  setSearchTerm,
  setSpellLevelFilter,
  setFavoritesOnly,
} from "../actions/interfaceActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ClassNameMap } from "@material-ui/styles";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { State } from "../reducers";
import { SpellbookProfileData } from "../reducers/spellbookData";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      //margin: theme.spacing(2),
      height: "100%",
    },
    text: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    notSetupYet: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textAlign: "center",
      margin: "auto",
    },
    searchField: {
      width: "calc(100% - 64px)",
    },
    spellList: {
      backgroundColor: theme.palette.background.paper,
    },
  });

const favoriteInactive = "#ddd";
const favoriteActive = "#f39c12";

interface SpellListDrawerContainerProps {
  classes: ClassNameMap;
  spellslotFilter: Array<boolean>;
  spellSearchTerm: string;
  spellbook: SpellbookProfileData;
  showFavoritesOnly?: boolean;
  isOpen: boolean;
  staticWidth?: boolean;
  setSpellLevelFilter: (level: number, value: boolean) => void;
  setSearchTerm: (searchTerm: string) => void;
  setFavoritesOnly: (value: boolean) => void;
}

let searchTimeout: NodeJS.Timeout;

function SpellListDrawerContainer(props: SpellListDrawerContainerProps) {
  let { classes } = props;

  const [state, setState] = useState({
    lastKnownSearchValue: "",
  });

  const rootElementReference = useRef<HTMLDivElement>(null);

  let noClassesSelected = props.spellbook.classes.length === 0;
  let noCustomSpells: boolean = true;

  if (props.spellbook.customSpells) {
    for (const value of Object.values(props.spellbook.customSpells)) {
      if (value.length > 0) {
        noCustomSpells = false;
        break;
      }
    }
  }

  const onLevelFilterChange =
    (level: number) => (event: React.MouseEvent<any>) => {
      props.setSpellLevelFilter(level, !props.spellslotFilter[level]);
    };

  const onSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (typeof searchTimeout !== "undefined") {
      clearTimeout(searchTimeout);
    }

    setState({
      ...state,
      lastKnownSearchValue: value,
    });

    searchTimeout = setTimeout(() => {
      props.setSearchTerm(value);
    }, 500);
  };

  const onFavoriteToggle = (event: React.MouseEvent) => {
    props.setFavoritesOnly(!props.showFavoritesOnly);
  };

  return (
    <React.Fragment>
      {noClassesSelected && noCustomSpells ? (
        <Typography
          className={`${classes.notSetupYet}`}
          style={{
            width: props.staticWidth ? 400 : "100%",
            padding: props.staticWidth ? 16 : 0,
          }}
        >
          Looks like you haven't set up your class and or custom spells yet,
          have a look at{" "}
          {
            <FontAwesomeIcon
              icon={faUsersCog as IconDefinition}
            ></FontAwesomeIcon>
          }{" "}
          Spellbook settings first!
        </Typography>
      ) : (
        <Grid
          className={classes.root}
          style={{
            width: props.staticWidth ? 400 : "100%",
            margin: props.staticWidth ? 16 : 0,
          }}
          wrap="nowrap"
          spacing={1}
          direction="column"
          ref={rootElementReference}
          container
        >
          <Grid item>
            <Typography className={classes.text}>Spell level filter</Typography>
          </Grid>
          <Grid item>
            <Grid
              spacing={1}
              direction="row"
              className="tour-filter-level"
              container
            >
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(0)}
                  variant={props.spellslotFilter[0] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  Cantrip
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(1)}
                  variant={props.spellslotFilter[1] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -1st-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(2)}
                  variant={props.spellslotFilter[2] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -2nd-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(3)}
                  variant={props.spellslotFilter[3] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -3rd-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(4)}
                  variant={props.spellslotFilter[4] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -4th-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(5)}
                  variant={props.spellslotFilter[5] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -5th-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(6)}
                  variant={props.spellslotFilter[6] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -6th-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(7)}
                  variant={props.spellslotFilter[7] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -7th-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(8)}
                  variant={props.spellslotFilter[8] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -8th-
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onLevelFilterChange(9)}
                  variant={props.spellslotFilter[9] ? "contained" : "outlined"}
                  color="primary"
                  size="small"
                >
                  -9th-
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>Spells</Typography>
          </Grid>
          <Grid style={{ textAlign: "center" }} item>
            <TextField
              onChange={onSearchFieldChange}
              className={`${classes.searchField} tour-filter-name`}
              defaultValue={props.spellSearchTerm}
              label="Search spell by name"
              variant="outlined"
              color="primary"
              size="small"
            />
            <IconButton
              className="tour-filter-favorites"
              onClick={onFavoriteToggle}
            >
              <FontAwesomeIcon
                color={
                  props.showFavoritesOnly ? favoriteActive : favoriteInactive
                }
                icon={faStar as IconDefinition}
              ></FontAwesomeIcon>
            </IconButton>
          </Grid>
          <Grid xs item>
            <SpellListContainer
              drawerReference={rootElementReference.current}
              showSpellDrawer={props.isOpen}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  setSearchTerm,
  setSpellLevelFilter,
  setFavoritesOnly,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  spellbook:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellslotFilter: state.interfaceData.spellslotFilter,
  spellSearchTerm: state.interfaceData.spellSearchTerm,
  showFavoritesOnly: state.interfaceData.showFavoritesOnly,
});

export default compose<
  React.FunctionComponent<Partial<SpellListDrawerContainerProps>>
>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SpellListDrawerContainer);
